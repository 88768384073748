import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AMDMAudienceRepresentationResponse, setAudienceAMDMState } from '../../audienceAMDMStatusSlice';

export const getDataStatus = createAsyncThunk<
  AMDMAudienceRepresentationResponse,
  {
    audienceRecordId: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(
  `amdm/data-status/get`,
  async ({ audienceRecordId }, { dispatch, extra }): Promise<AMDMAudienceRepresentationResponse> => {
    const amdmService = extra.factory.getAmdmService();
    try {
      const response = await amdmService.getDataStatus(audienceRecordId);
      return response;
    } catch (e) {
      if (e instanceof Error) {
        throw e.message;
      } else {
        throw undefined;
      }
    }
  },
);
