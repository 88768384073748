import { Serie, SerieTag, TotalValue } from './interface';
import Highcharts from 'highcharts';
import { tagsMatch } from '../helpers/tag';
import { applyPermutations, getPermutationOrders } from '../helpers/permutations';

export const serieReduce = (serie: Serie, callback: (n: number, i: number) => number): Serie => {
  const result = { ...serie };
  result.values = serie.values.map(callback).map((value) => (isNaN(value) || value === Infinity ? 0 : value));
  return result;
};

export const serieRatio = (serie: Serie, coef: number): Serie => {
  return serieReduce(serie, (n: number) => Math.round(n * coef));
};

/**
 * Return a key/value portion of a SerieTag based on selected properties
 * If one of this properties are missing, return undefined
 *
 * @param properties   the properties wanted in the result
 * @returns
 */
export const tagsValue = (properties: string[]): ((tags: SerieTag) => SerieTag | undefined) => {
  return (tags: SerieTag) => {
    const response: SerieTag = {};
    for (const p of properties) {
      if (!tags[p]) {
        return undefined;
      }
      response[p] = tags[p];
    }
    return response;
  };
};

/**
 * Return true if the tags are contained in the serie global tags
 *
 * @param serie
 * @param tags
 * @returns
 */
export const serieGlobalTagsMatch = (tags: SerieTag): ((serie: Serie) => boolean) => {
  return (serie: Serie): boolean => tagsMatch(tags)(serie.globalTags);
};

export const convertSerieToHighChart = (
  series: Serie[],
  tagConvertion: (globalTags: SerieTag) => SerieTag,
  xAxisProperties: Highcharts.XAxisOptions,
): Highcharts.Options => {
  if (series.length === 0) {
    return { series: [], xAxis: xAxisProperties };
  }
  return {
    series: series.map(
      (serie: Serie) =>
        ({
          ...tagConvertion(serie.globalTags),
          data: serie.values.map((el) => ({
            y: el,
          })),
        } as Highcharts.SeriesXrangeOptions),
    ),
    xAxis: {
      ...xAxisProperties,
      // 0 is arbitrary
    },
  };
};

/**
 * concatenation of n series in a new serie stack
 * the globalTags are the merge of the series globalTags, the first serie value taking precedence
 *
 * @param serie1
 * @param serie2
 * @param transferedGlobalTags : field from globalTags source serie to transfer in matching series
 */
export const serieConcat = (series: Serie[], transferedGlobalTags: string[] = []): Serie => {
  const transferedTags: SerieTag[] = [...Array(series.length).keys()].map((i: number) => {
    const tags: SerieTag = {};
    transferedGlobalTags.forEach((tag) => {
      tags[tag] = series[i].globalTags[tag];
    });
    return tags;
  });

  const tags = series.reduce((prev: SerieTag[], current: Serie, index: number) => {
    return [...prev, ...current.tags.map((tag) => ({ ...tag, ...transferedTags[index] }))];
  }, []);

  const values = series.reduce((prev: number[], current: Serie) => {
    return [...prev, ...current.values];
  }, []);

  const globalTags = series.reduce((prev: SerieTag, current: Serie) => {
    return { ...current.globalTags, ...prev };
  }, {});

  const totalValues = series.reduce((prev: TotalValue[], current: Serie) => {
    return [...current.totalValues, ...prev];
  }, [] as TotalValue[]);

  return {
    values,
    tags,
    globalTags,
    totalValues,
  };
};

/**
 * Return the index of the first occurence of some particular tag/value in a serie,
 * or undefined if not found
 *
 * @param tag
 * @returns
 */
export const serieTagIndexOf = (tag: SerieTag): ((serie: Serie) => number | undefined) => {
  return (serie: Serie) => {
    return serie.tags.findIndex((serieTag) => tagsMatch(tag)(serieTag));
  };
};

/**
 * Return the first occurence of some particular tag/value in a serie,
 * or undefined if not found
 *
 * @param tag
 * @returns
 */
export const serieTagFind = (tag: SerieTag): ((serie: Serie) => SerieTag | undefined) => {
  return (serie: Serie) => {
    return serie.tags.find((serieTag) => tagsMatch(tag)(serieTag));
  };
};

/**
 * Return a callback that help to enrich serie tags using a source serie
 *
 * @param sourceSerie
 * @param referenceTagNames   list of tag names to compare with source serie
 * @param enrichTagNames      list of tag names that will be copied when value matches
 * @returns
 */
export const serieTagEnrich = (
  sourceSerie: Serie,
  referenceTagNames: string[],
  enrichTagNames: string[],
): ((serie: Serie) => Serie) => {
  return (serie: Serie) => {
    const getReferenceTags = tagsValue(referenceTagNames);
    const getEnrichTags = tagsValue(enrichTagNames);
    return {
      values: serie.values,
      globalTags: serie.globalTags,
      totalValues: serie.totalValues,
      tags: serie.tags.map((tags: SerieTag) => {
        const referenceTags = getReferenceTags(tags);
        if (!referenceTags) {
          return tags;
        } else {
          const targetTags = serieTagFind(referenceTags)(sourceSerie);
          if (!targetTags) {
            return tags;
          }
          return { ...tags, ...getEnrichTags(targetTags) };
        }
      }),
    };
  };
};

/**
 * Return index of tags - and values - where tags match the passed function
 *
 * @param testFuncion
 * @returns
 */
export const serieIndexesOf = (testFuncion: (tag: SerieTag) => boolean): ((serie: Serie) => number[]) => {
  return (serie: Serie): number[] => {
    const results: number[] = [];
    serie.tags.forEach((tag, i) => {
      if (testFuncion(tag)) {
        results.push(i);
      }
    });
    return results;
  };
};

/**
 * Return a version of the serie containing only the items at the given indexes
 *
 * @param indexes
 * @returns
 */
export const serieFromIndexes = (indexes: number[]): ((serie: Serie) => Serie) => {
  return (serie_: Serie): Serie => {
    const { tags, values, totalValues } = indexes.reduce(
      (acc, item) => {
        const tag = serie_.tags[item];
        const values = serie_.values[item];
        acc.tags.push(tag);
        acc.values.push(values);
        const totalValue = serie_.totalValues.find(({ variableId }) => variableId === tag.responseId);
        if (totalValue) acc.totalValues.push(totalValue);

        return acc;
      },
      {
        tags: [],
        values: [],
        totalValues: [],
      } as Pick<Serie, 'tags' | 'totalValues' | 'values'>,
    );
    return {
      globalTags: serie_.globalTags,
      tags,
      values,
      totalValues,
    };
  };
};

/**
 * Filter the serie by tags using a function
 *
 * @param tagName
 * @param value
 * @returns
 */
export const serieTagFilter = (testFuncion: (tag: SerieTag) => boolean): ((serie: Serie) => Serie) => {
  return (serie: Serie): Serie => {
    const indexes: number[] = serieIndexesOf(testFuncion)(serie);
    return serieFromIndexes(indexes)(serie);
  };
};

/**
 * Take an array of serie in parameter and return new array based on concatenation of series grouped by the value of given globalTags
 * Resulting globalTags are the merge of the series globalTags, the first serie value taking precedence
 * Resulting series can be enriched with values from original globalTags series using enrichTagNames
 *
 * @param series
 * @param globalTagName
 * @param enrichTagNames
 * @returns
 */
export const seriesRegroup = (
  globalTagName: string | string[],
  enrichTagNames: string[] = [],
): ((series: () => Serie[]) => Serie[]) => {
  if (typeof globalTagName === 'string') {
    globalTagName = [globalTagName];
  }
  return (series: () => Serie[]): Serie[] => {
    const map: Map<string | number | boolean, Serie[]> = new Map();
    series().forEach((serie) => {
      const uniqueKey = (globalTagName as string[]).map((tag) => serie.globalTags[tag] || '.').join();
      if (!map.has(uniqueKey)) {
        map.set(uniqueKey, []);
      }
      map.set(uniqueKey, [...map.get(uniqueKey)!, serie]);
    });
    return [...map.values()].map((series) => serieConcat(series, enrichTagNames));
  };
};

/**
 * Return globaltags of an array of series to an array of SerieTag
 *
 * @param series
 * @returns
 */
export const seriesGlobaltags = (series: () => Serie[]): (() => SerieTag[]) => {
  return () => series().map((serie) => serie.globalTags);
};

/**
 * Transpose an array of serie
 *
 * globalTags are used for tags,
 * tags are merged to create globalTags are permuted
 *
 * @param tagList
 * @returns
 */
export const seriesTranspose = (series: Serie[]): Serie[] => {
  if (series.length === 0) {
    return series;
  }
  const len = series[0].values.length;

  return [...Array(len).keys()].map((i: number) => {
    let globalTags = {};
    const values: number[] = [],
      tags: SerieTag[] = [],
      totalValues: TotalValue[] = [];
    series.forEach((serie) => {
      globalTags = { ...serie.tags[i], ...globalTags };
      values.push(serie.values[i]);
      tags.push(serie.globalTags);
      totalValues.push(serie.totalValues[i]);
    });
    return { globalTags, values, tags, totalValues };
  });
};

export const serieAddGlobalTags = (tags: SerieTag): ((serie: Serie) => Serie) => {
  return (serie: Serie): Serie => ({
    ...serie,
    globalTags: { ...tags, ...serie.globalTags },
  });
};

export const serieKeepTags = (tagNames: string[]): ((serie: Serie) => Serie) => {
  return (serie: Serie): Serie => ({
    ...serie,
    tags: serie.tags.map((tags: SerieTag) => {
      const result: SerieTag = {};
      tagNames.forEach((tagName) => (result[tagName] = tags[tagName]));
      return result;
    }),
  });
};

/**
 * Return a new serie sorted using the fieldName, but according to the serieTag order
 *
 * @param toSort
 * @param reference
 */
export const matchSerieTagOrder = (reference: SerieTag[], fieldName: string): ((toSort: Serie) => Serie) => {
  return (toSort: Serie) => {
    const tag: SerieTag = {};
    const result: Serie = { globalTags: { ...toSort.globalTags }, tags: [], values: [], totalValues: [] };
    reference.forEach((referenceTags: SerieTag) => {
      const referenceValue = referenceTags[fieldName];
      if (!referenceValue) {
        throw `tag ${fieldName} was not found in reference serie`;
      }
      tag[fieldName] = referenceValue;
      const index = serieTagIndexOf(tag)(toSort);
      if (index === undefined) {
        throw `tag ${fieldName} was not found in source serie`;
      }
      result.tags.push(toSort.tags[index]);
      result.values.push(toSort.values[index]);
      result.totalValues.push(toSort.totalValues[index]);
    });
    return result;
  };
};

/**
 * Return all possible values of a globalTags in an array of Serie
 *
 * @param tagName
 * @param series
 * @returns
 */
export const globalTagValues = (tagName: string, series: Serie[]): (string | number | boolean)[] => {
  return series
    .map((serie) => serie.globalTags[tagName] as string)
    .filter((tagName: string, index, self) => self.indexOf(tagName) === index);
};

/**
 * Explode array of serie to an array of array of serie, based on the value of a globalTag
 *
 * @param globalTagName
 * @param series
 */
export const seriesExplode = (globalTagName: string, series: Serie[]): Serie[][] => {
  const tagValues = globalTagValues(globalTagName, series);
  return tagValues.map((tagValue) => series.filter((serie: Serie) => serie.globalTags[globalTagName] === tagValue));
};

/**
 * Return the permutation based on a sort of the serie's values
 *
 * @param compareFunction
 * @param serie
 * @returns
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const seriePermutationsOfValues = (compareFunction: (a: any, b: any) => number, serie: Serie): Array<number> => {
  const sorted = [...serie.values].sort(compareFunction);
  return getPermutationOrders(sorted, serie.values);
};

/**
 * Apply permutations from a call to getPermutationOrders
 * Typical usecase : order serie regarding a sort applied on another serie
 *
 * @param globalTagName
 * @param series
 */
export const serieApplyPermutations = (permutations: Array<number>, serie: Serie): Serie => ({
  ...serie,
  tags: applyPermutations<SerieTag>(permutations)(serie.tags),
  values: applyPermutations<number>(permutations)(serie.values),
  totalValues: applyPermutations<TotalValue>(permutations)(serie.totalValues),
});
