import { Dispatch } from 'redux';
import {
  SET_RUN_ID,
  SET_JOB_STATUS,
  SET_RESULTS,
  SET_LOADING,
  SET_JOB_RUN_START,
  SET_JOB_ESTIMATED_TIME,
  SET_SAVED_RESULTS,
  SET_NAME_CATEGORY,
  SET_RUN_ID_BQ,
  SET_RUN_ID_BQ_STATUS,
  SET_COUNTRY_CODE,
} from './constants';
import { Clusters, PropensityResults } from '../../../modules/common/interface/interface';

interface IResults {
  run_id: string;
  statusBQ: string | undefined;
  status: string;
  name: string | undefined;
  category: string | undefined;
  loading: boolean;
  jobTimeStart: number;
  estimatedTime:
    | {
        lower_bound: string;
        upper_bound: string;
      }
    | string;
  countryCode: number;
}

export const databricksJobStart = (results: IResults, actionID: string, module: string) => (dispatch: Dispatch) => {
  if (!actionID) return;

  dispatch({
    type: SET_JOB_STATUS,
    payload: {
      value: results.status,
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_COUNTRY_CODE,
    payload: {
      value: results.countryCode,
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_JOB_RUN_START,
    payload: {
      value: results.jobTimeStart,
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_JOB_ESTIMATED_TIME,
    payload: {
      value: results.estimatedTime,
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_RUN_ID,
    payload: {
      value: results.run_id.toString(),
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_RUN_ID_BQ_STATUS,
    payload: {
      value: results.statusBQ,
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_NAME_CATEGORY,
    payload: {
      value: { name: results.name, category: results.category },
      actionID: actionID,
      module,
    },
  });

  dispatch({
    type: SET_LOADING,
    payload: {
      value: results.loading,
      actionID: actionID,
      module,
    },
  });
};

export const databricksJobResults =
  (
    results: {
      status: string;
      response: Clusters;
      loading: boolean;
    },
    actionID: string,
    module: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: SET_JOB_STATUS,
      payload: {
        value: results.status,
        actionID: actionID,
        module,
      },
    });
    dispatch({
      type: SET_RESULTS,
      payload: {
        value: results.response,
        actionID: actionID,
        module,
      },
    });
    dispatch({
      type: SET_LOADING,
      payload: {
        value: results.loading,
        actionID: actionID,
        module,
      },
    });
  };

export const propensityBQJobResults =
  (
    results: {
      run_ID: string;
      status: string;
    },
    actionID: string,
    module: string,
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: SET_RUN_ID_BQ,
      payload: {
        value: results.run_ID,
        actionID: actionID,
        module,
      },
    });
    dispatch({
      type: SET_RUN_ID_BQ_STATUS,
      payload: {
        value: results.status,
        actionID: actionID,
        module,
      },
    });
  };

export const propensityDataJobResults = (status: string, actionID: string, module: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_JOB_STATUS,
    payload: {
      value: status,
      actionID: actionID,
      module,
    },
  });
};

export const setPropensityDataResults =
  (results: PropensityResults, actionID: string, module: string) => (dispatch: Dispatch) => {
    dispatch({
      type: SET_RUN_ID_BQ_STATUS,
      payload: {
        value: results.status,
        actionID: actionID,
        module,
      },
    });
    dispatch({
      type: SET_RESULTS,
      payload: {
        value: {
          toChartData: results.toChartData,
          yougovIDs: results.yougovIDs,
          distinct: results.distinct,
          topVariables: results.topVariables,
        },
        actionID: actionID,
        module,
      },
    });
    dispatch({
      type: SET_LOADING,
      payload: {
        value: false,
        actionID: actionID,
        module,
      },
    });
  };

export const setLoading = (value: boolean, actionID: string, module: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_LOADING,
    payload: {
      value: value,
      actionID: actionID,
      module,
    },
  });

export const setSavedResults =
  (value: number | undefined | [number, number], actionID: string, module: string) => (dispatch: Dispatch) =>
    dispatch({
      type: SET_SAVED_RESULTS,
      payload: {
        value: value,
        actionID: actionID,
        module,
      },
    });
