import React, { FC } from 'react';
import Color from 'color';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import FormatedNumber from './FormatedNumber';
import { Serie, SerieTag, TotalValue } from '../../core/series/interface';
import { globalTagValues, seriesTranspose } from '../../core/series/serie';

const lightColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(95).hex();
};

const blendColor = (hex: string): string => {
  const lighten = Color(lightColor(hex));
  return lighten.mix(Color('#25cacf'), 0.1).hex();
};

const mediumColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(50).hex();
};

export interface TableSortCriteria {
  section: string | undefined;
  column: string;
  order: 'ASC' | 'DESC';
}

export interface Props {
  series: Serie[];
  sectionGroupTag: string | undefined;
  columnGroupTag: string;
  sortAction: (criteria: TableSortCriteria) => void;
  formatLineLabel: (tags: SerieTag, globalTags: SerieTag, offTitle: boolean) => React.ReactElement;
  sortCriteria: TableSortCriteria | undefined;
  valueFormatter?: (val: number, globalTags: SerieTag, tags: SerieTag) => JSX.Element;
}

const SerieTable: FC<Props> = ({
  series,
  sectionGroupTag,
  columnGroupTag,
  sortAction,
  formatLineLabel,
  sortCriteria,
  valueFormatter,
}) => {
  const changeSortCriteria = (sectionName: string | undefined, columnName: string, order: 'ASC' | 'DESC') => {
    sortAction({ section: sectionName, column: columnName, order });
  };

  // empty series ?
  if (series.length === 0) {
    return <></>;
  }

  const arbitraryTags = series[0].tags;
  const sectionValues = sectionGroupTag ? (globalTagValues(sectionGroupTag, series) as string[]) : [];
  const columnValues = globalTagValues(columnGroupTag, series) as string[];

  sectionValues.forEach((sectionName: string) => {
    columnValues.forEach((columnName: string) => {
      const column = series.filter(
        (serie: Serie) =>
          serie.globalTags[sectionGroupTag!] === sectionName && serie.globalTags[columnGroupTag] === columnName,
      );
      if (column.length === 0) {
        // watermark column replacement
        const globalTags: Record<string, string | number | boolean> = {};
        globalTags[sectionGroupTag!] = sectionName;
        globalTags[columnGroupTag] = columnName;
        series.push({
          globalTags,
          tags: arbitraryTags,
          // fill of undefined , inspite of the fact that Serie.value are numbers !
          values: Array(arbitraryTags.length).fill(undefined) as number[],
          totalValues: Array(arbitraryTags.length).fill(undefined) as TotalValue[],
        });
      }
    });
  });

  const ordonnedSeries: Serie[] = sectionValues
    .map((sectionName: string) =>
      columnValues
        .map((columnName: string) =>
          series.filter(
            (serie: Serie) =>
              serie.globalTags[sectionGroupTag!] === sectionName && serie.globalTags[columnGroupTag] === columnName,
          ),
        )
        .flat(),
    )
    .flat();

  const sections = seriesTranspose(ordonnedSeries);

  const formatValue = (val: number, globalTags: SerieTag, tags: SerieTag): JSX.Element => {
    return typeof valueFormatter === 'function' ? (
      valueFormatter(val, globalTags, tags)
    ) : (
      <FormatedNumber value={val} />
    );
  };

  return (
    <table className="numbers-table default-table">
      {sectionGroupTag && (
        <thead>
          <tr>
            <th></th>
            {ordonnedSeries
              .map((serie) => serie.globalTags[sectionGroupTag])
              .filter((value, index, self) => index === self.indexOf(value))
              .map((value) => (
                <React.Fragment key={`${sectionGroupTag}  ${value as string}`}>
                  <th colSpan={columnValues.length} className="title">
                    {value}
                  </th>
                  {/* <th></th> */}
                </React.Fragment>
              ))}
          </tr>
        </thead>
      )}

      <tbody>
        <tr>
          <th className="label"></th>
          {ordonnedSeries.map((s: Serie, i) => {
            const tag = s.globalTags;
            const colName = tag[columnGroupTag] as string;
            // const color = tag.color as string;
            const section = sectionGroupTag ? (tag[sectionGroupTag] as string) : undefined;
            return (
              <React.Fragment key={i}>
                <th>
                  <div className="default-table__header">
                    {tag[columnGroupTag]}
                    {sortCriteria?.column !== tag[columnGroupTag] ||
                      sortCriteria?.section !== tag[sectionGroupTag!] || (
                        <div className="default-table__filter">
                          {sortCriteria?.order !== 'ASC' || (
                            <CaretDownOutlined onClick={() => changeSortCriteria(section, colName, 'DESC')} />
                          )}
                          {sortCriteria?.order !== 'DESC' || (
                            <CaretUpOutlined onClick={() => changeSortCriteria(section, colName, 'ASC')} />
                          )}
                        </div>
                      )}
                    {(sortCriteria?.column === tag[columnGroupTag] &&
                      sortCriteria?.section === tag[sectionGroupTag!]) || (
                      <div className="default-table__filter">
                        <CaretUpOutlined onClick={() => changeSortCriteria(section, colName, 'DESC')} />
                        <CaretDownOutlined onClick={() => changeSortCriteria(section, colName, 'ASC')} />
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="spacer"></th> */}
              </React.Fragment>
            );
          })}
        </tr>

        {sections.map((line, i) => {
          return (
            <tr
              key={i}
              className={(!(i % 2) ? 'odd' : '') + (i == 0 ? ' first' : '') + (i == sections.length - 1 ? ' last' : '')}
            >
              <td className="line-name">{formatLineLabel(line.tags[0], line.globalTags, false /* arbitrary */)}</td>
              {line.values.map((val, j) => (
                <React.Fragment key={j}>
                  <td
                    style={{
                      backgroundColor: !(i % 2)
                        ? blendColor(line.tags[j].color as string)
                        : lightColor(line.tags[j].color as string),
                    }}
                  >
                    {formatValue(val, line.globalTags, line.tags[j])}
                  </td>
                  {/* <td className="spacer"></td> */}
                </React.Fragment>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SerieTable;
