import { FC, useContext, useEffect, useState } from 'react';
import Infos from './Infos/Infos';
import { Context } from '../../Common/Context/Context';
import TabsSelector from './Tabs/TabsSelector';
import ApplicationLayout from '../../ApplicationLayout';
import { useDispatch, useSelector } from 'react-redux';
import SegmentsWrapper from '../../MetaComponents/Segments/SegmentsWrapper';
import segmentSlice, { selectSegmentByScope } from '../../store/segmentSlice';
import { GlobalState } from '../../store/global';
import { getCountryDimFromIso } from '../../../core/helpers/country';
import { initialSegmentProperties } from '../../../core/segment/helper';
import { useNavigate, useParams } from 'react-router';
import { PageProperties } from '../../interface';
import { CountryMetadata, SegmentOrigin, countryMetadatas } from 'hawaii';
import { useSetLanguage } from '../../Common/hooks/useSettingsUI';
import { useMemo } from 'react';
import { setSelectedCountry } from '../../store/secmdmSlice';
import _ from 'lodash';

const SegmentBuilder: FC = () => {
  const setLanguage = useSetLanguage();

  const handleSelectCountry = (iso: string) => {
    const meta: CountryMetadata = getCountryDimFromIso(iso);
    dispatch(setSelectedCountry(meta));
    setLanguage(iso, meta);
    setTotalRespondent(undefined);
    navigate(PageProperties['segment-builder'].url.replace(':country', iso));
  };

  const handleAddSegment = (isSmart?: boolean) => {
    const countryIsos = [selectedCountry.iso];

    const { payload } = dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          countryIsos,
          dataScope: 'Original',
          origin: SegmentOrigin.SEGMENT_BUILDER,
          isSmart,
        }),
      }),
    );
    return payload.segment;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { country: strCountry } = useParams();

  const user = useSelector((state: GlobalState) => state.secmdmState).user;
  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const validCountries = useMemo(
    () =>
      countries
        .filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso))
        .sort((a, b) => (a.iso < b.iso ? -1 : 1)),
    [countries],
  );

  const isoAlpha2 = validCountries.find((country) => country.iso.toLowerCase() === user.isoAlpha2?.toLowerCase())
    ? user.isoAlpha2
    : validCountries[0].iso;

  const selectedCountry = useMemo(
    () => getCountryDimFromIso(!strCountry || strCountry === ':country' ? (isoAlpha2 as string) : strCountry),
    [strCountry, user],
  );

  useEffect(() => {
    handleSelectCountry(selectedCountry.iso);
  }, [selectedCountry]);

  const { factory: factory } = useContext(Context);

  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);

  const responseLevelService = factory.getResponseLevelService();

  const [totalRespondent, setTotalRespondent] = useState<number | undefined>(undefined);

  const segments = useSelector((state: GlobalState) => selectSegmentByScope(state, 'Original', [selectedCountry]));

  useEffect(() => {
    const run = async () => {
      const totalRespondent = await responseLevelService.getCountryRespondents(selectedCountry.id, 'Original');
      setTotalRespondent(totalRespondent);
    };
    void run();
  }, [strCountry, selectedLanguage, selectedCountry]);

  const leftSide = (
    <>
      <Infos country={selectedCountry} selectCountryAction={handleSelectCountry} totalRespondent={totalRespondent} />
      <SegmentsWrapper
        segments={segments}
        countryIsos={[selectedCountry.iso]}
        newSegmentAction={handleAddSegment}
        dataScope="Original"
        link="segment-editor"
        origin={SegmentOrigin.SEGMENT_BUILDER}
      />
    </>
  );

  const rightSide = <TabsSelector selectedCountry={selectedCountry} selectedLanguage={selectedLanguage} />;

  return <ApplicationLayout pageName="segment-builder" leftSide={leftSide} rightSide={rightSide} />;
};

export default SegmentBuilder;
