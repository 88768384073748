import { FC, useContext, useEffect, useState, useMemo } from 'react';
import Infos from '../../Pages/SegmentBuilder/Infos/Infos';
import { Context } from '../../Common/Context/Context';
import ApplicationLayout from '../../ApplicationLayout';
import { useDispatch, useSelector } from 'react-redux';
import SegmentsWrapper from '../../modules/clusterSegment/components/clusterSegmentsWrapperComponent';
import segmentSlice, { selectSegmentById, selectSegmentByScope } from '../../store/segmentSlice';
import { GlobalState } from '../../store/global';
import { getCountryDimFromIso } from '../../../core/helpers/country';
import { initialSegmentProperties } from '../../../core/segment/helper';
import { useNavigate, useParams } from 'react-router';
import { PageProperties } from '../../interface';
import { CountryMetadata, SegmentOrigin, countryMetadatas } from 'hawaii';
import Tabs from '../../modules/clusterSegment/components/tabs';
import { MdOutlineSettings } from 'react-icons/md';
import { useSetLanguage } from '../../Common/hooks/useSettingsUI';
import { useDataBricks } from '../../modules/common/hooks/useDataBricks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { setSelectedCountry } from '../../store/secmdmSlice';
import _ from 'lodash';
import { SegmentJobState } from '../../modules/common/interface/interface';
import { getSegmentEditorData } from '../../store/segmentEditionSlice';

const ClusterBuilder: FC = () => {
  const { country: strCountry, segmentID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorData = useSelector((state: GlobalState) => getSegmentEditorData(state));
  const selectedSegment = useSelector((state: GlobalState) => selectSegmentById(state, editorData.segmentId!));

  const setLanguage = useSetLanguage();
  const { getResultsClusterAnalysisPipeline, jobStatus, loading, databricksList } = useDataBricks(
    segmentID as string,
    'clusters',
  );

  useEffect(() => {
    if (!segmentID || segmentID === ':segmentID' || !databricksList) return;

    let successNum = 0;
    const byCountry = {} as { [key: string]: SegmentJobState };
    Object.keys(databricksList).forEach((key) => {
      const item = databricksList[key];
      if (item.countryCode === selectedCountry.id) {
        byCountry[key] = item;
      }
    });

    const checkResults = async (id: string) => {
      try {
        await getResultsClusterAnalysisPipeline(id);
      } catch (error) {
        console.error('Error of running cluster results job.', error);
      }
    };
    if (successNum !== Object.keys(byCountry).length) {
      const interval = setInterval(() => {
        const keys = Object.keys(byCountry);
        if (keys.length === 0) {
          clearInterval(interval);
          return;
        }
        keys.forEach((key) => {
          if (byCountry[key].jobStatus === 'SUCCESS') {
            successNum++;
          }
          if (byCountry[key].jobStatus === 'RUNNING') {
            void checkResults(key);
          }
        });
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
    return;
  }, [getResultsClusterAnalysisPipeline, jobStatus, segmentID, databricksList]);

  const handleSelectCountry = (iso: string) => {
    const meta: CountryMetadata = getCountryDimFromIso(iso);
    dispatch(setSelectedCountry(meta));
    setLanguage(iso, meta);
    setTotalRespondent(undefined);
    navigate(
      PageProperties['cluster-builder'].url
        .replace(':country', iso)
        .replace(':segmentID', !selectedSegment ? '' : (segmentID as string)),
    );
  };

  const handleAddSegment = () => {
    const countryIsos = [selectedCountry.iso];
    const { payload } = dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          countryIsos,
          dataScope: 'Original',
          origin: SegmentOrigin.CLUSTER_BUILDER,
        }),
      }),
    );
    return payload.segment;
  };

  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const user = useSelector((state: GlobalState) => state.secmdmState).user;
  const validCountries = useMemo(
    () =>
      countries
        .filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso))
        .sort((a, b) => (a.iso < b.iso ? -1 : 1)),
    [countries],
  );

  const isoAlpha2 = validCountries.find((country) => country.iso.toLowerCase() === user.isoAlpha2?.toLowerCase())
    ? user.isoAlpha2
    : validCountries[0].iso;

  const selectedCountry = useMemo(
    () => getCountryDimFromIso(!strCountry || strCountry === ':country' ? (isoAlpha2 as string) : strCountry),
    [strCountry, user],
  );

  useEffect(() => {
    handleSelectCountry(selectedCountry.iso);
  }, [selectedCountry]);

  const { factory: factory } = useContext(Context);

  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);

  const responseLevelService = factory.getResponseLevelService();

  const [totalRespondent, setTotalRespondent] = useState<number | undefined>(undefined);

  const segments = useSelector((state: GlobalState) => selectSegmentByScope(state, 'Original', [selectedCountry]));

  useEffect(() => {
    const run = async () => {
      const totalRespondent = await responseLevelService.getCountryRespondents(selectedCountry.id, 'Original');
      setTotalRespondent(totalRespondent);
    };
    void run();
  }, [strCountry, selectedLanguage, selectedCountry]);

  const leftSide = (
    <>
      <Infos country={selectedCountry} selectCountryAction={handleSelectCountry} totalRespondent={totalRespondent} />
      <SegmentsWrapper
        segments={segments}
        countryIsos={[selectedCountry.iso]}
        newSegmentAction={handleAddSegment}
        dataScope="Original"
        link="cluster-editor"
        origin={SegmentOrigin.CLUSTER_BUILDER}
      />
    </>
  );

  const rightSide = !segments.length ? (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '45%',
          bottom: '50%',
        }}
      >
        <p
          style={{
            color: '#656565',
          }}
        >
          Select variables and responses that you want to include in the Clustering Analysis model
        </p>
      </div>
    </div>
  ) : loading ? (
    <div className="matching-progress">
      <MdOutlineSettings className="hawaii-rotating" />
      <span>
        Cluster analysis is running, <br />
        please wait...
      </span>
    </div>
  ) : jobStatus === 'INTERNAL_ERROR' ? (
    <div className="matching-progress" style={{ fontSize: '3em', color: 'red', textAlign: 'center' }}>
      <ExclamationCircleOutlined style={{ fontSize: '2em', color: 'red', textAlign: 'center', marginTop: '150px' }} />
      <br />
      <span>Internal error, try again...</span>
    </div>
  ) : (
    <Tabs />
  );

  return (
    <ApplicationLayout
      pageName="cluster-builder"
      pageSubtitle={<span style={{ fontSize: '1.2rem' }}> - Beta Version</span>}
      leftSide={leftSide}
      rightSide={rightSide}
    />
  );
};

export default ClusterBuilder;
