import { FC, useContext, useEffect, useState } from 'react';
import { MdSave, MdContentCopy, MdCheck } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { getSegmentVariablesNumber } from '../../../core/segment/helper';
import { Popover } from '../../Common/Popover';
import EditionPopup from '../../Common/SegmentCard/EditionPopup';
import segmentSlice from '../../store/segmentSlice';
import seriesSlice from '../../store/seriesSlice';
import { saveSegmentinProfilesThunk, copySegmentProfilesThunk } from '../../store/thunk/saveSegment';
import UJContext from './UJContext';
import { GlobalState } from '../../store/global';
import { getAudienceFromAmdmThunk } from '../../store/thunk/amdm/getAudience';
import { setAppIsLoadingState } from '../../store/appIsLoadingSlice';
import GetAudienceModal from './AMDM/GetAudienceModal';
import SaveAudienceModal from './AMDM/SaveAudienceModal';
import { setGetAudienceModalOpen, setSaveAudienceAction, setSaveAudienceModalOpen } from '../../store/amdmSlice';
import { SegmentWithUiMeta } from 'hawaii';
import { IconArrowLeft, IconEdit, IconMatch } from '../../Svg/HavasIcons';
import { segmentEditionReset } from '../../store/segmentEditionSlice';

import { SettingOutlined, FormOutlined } from '@ant-design/icons';
import { extractPath } from '../../../core/helpers/extractLocation';
import { UKPanelistAlert } from './UKPanelistAlert';
interface Props {
  urlBack: string;
  parentSegment?: SegmentWithUiMeta;
  handleActivateStudioDataBricksMatch?: () => void;
  handleSettings?: () => void;
  setSelectedStage?: React.Dispatch<React.SetStateAction<'Definition' | 'Matching' | 'Smart Builder'>>;
  setCurrentStage?: React.Dispatch<React.SetStateAction<'Definition' | 'Matching' | 'Smart Builder'>>;
  setWarningOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  loading?: boolean;
}

const SegmentEditorHeader: FC<Props> = ({
  urlBack,
  parentSegment,
  handleSettings,
  setSelectedStage,
  loading,
  setCurrentStage,
  setWarningOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const context = useContext(UJContext);

  const [segment, setSegment] = useState<SegmentWithUiMeta>(context.segment);
  const [errMsg, setErrMsg] = useState<string>('');

  const [openPropertiesDial, setOpenPropertiesDial] = useState<boolean>(false);
  const [ukPanelistAlertOpen, setUkPanelistAlertOpen] = useState<boolean>(false);

  const segments = useSelector((state: GlobalState) => state.segments);
  const importerSegments = useSelector((state: GlobalState) => state.importerSegments.list);

  const amdmState = useSelector((state: GlobalState) => state.amdmState);

  const emptySegment = getSegmentVariablesNumber(segment) === 0;

  const user = useSelector((state: GlobalState) => state.secmdmState).user;

  useEffect(() => {
    if (!parentSegment) {
      setSegment(context.segment);
    } else {
      setSegment(parentSegment);
    }
  }, [parentSegment, context]);

  const validateInput = (input: string): boolean => {
    const allowedCharacters = /^[a-zA-Z0-9 _.,"'()éèêëàâäîïôöùûüç]*$/;

    if (!allowedCharacters.test(input)) {
      return false;
    }

    return true;
  };

  const handleEdit = (name: string, color: string): boolean => {
    const isNameExists =
      segments.some((s) => s.name.toLowerCase() === name.toLowerCase()) ||
      importerSegments.some((s) => s.name.toLowerCase() === name.toLowerCase());
    const isValide = validateInput(name);
    if (!isValide) {
      setErrMsg(`Audience name "${name}" as invalid characters.`);
      return true;
    }

    if (!isNameExists && name !== '') {
      dispatch(segmentSlice.actions.propertiesUpdate({ id: segment.id, color, name }));
      dispatch(
        seriesSlice.actions.updateSerieGlobalTags({
          selectTags: {
            segmentId: segment.id,
          },
          tags: {
            name: name,
            color: color,
          },
        }),
      );

      setOpenPropertiesDial(false);
      return isNameExists;
    } else if (name === '') {
      setErrMsg(`Please provide a name for your audience before saving it.`);
      return true;
    } else if (isNameExists) {
      setErrMsg(`An audience with this name already exists. Please choose another name for your audience.`);
      return isNameExists;
    }

    return false;
  };

  const handleSaveAudience = () => {
    const minResp = 3000;
    if (
      user.isoAlpha2?.toLowerCase() === 'gb' &&
      segment.countryIsos[0].toLowerCase() == 'gb' &&
      segment.uiMeta.stats !== null &&
      segment.uiMeta.stats < minResp
    ) {
      setUkPanelistAlertOpen(true);
      return;
    }
    if (amdmState.connection.isConnected && !segment.amdmInfos) {
      dispatch(setSaveAudienceAction('save'));
      dispatch(setSaveAudienceModalOpen(true));
    } else {
      dispatch(setAppIsLoadingState({ state: true }));
      dispatch(saveSegmentinProfilesThunk({ segment }));
    }
  };

  const handleCopyAudience = () => {
    if (amdmState.connection.isConnected) {
      dispatch(setSaveAudienceAction('copy'));
      dispatch(setSaveAudienceModalOpen(true));
    } else {
      dispatch(copySegmentProfilesThunk({ segment }));
    }
  };

  const handleGetFromAmdm = () => {
    if (!segment.amdmInfos) {
      setErrMsg(`Audience name "${segment.name}" does not have any Amdm infos.`);
    } else {
      dispatch(getAudienceFromAmdmThunk({ audienceRecordId: segment.amdmInfos.audienceRecordId }));
      dispatch(setGetAudienceModalOpen(true));
    }
  };

  const getAmdmButton = () => {
    if (!emptySegment && !segment.uiMeta.modified) {
      if (amdmState.connection.isConnected && segment.amdmInfos) {
        return (
          <div
            className="hawaii-icon-button"
            onClick={handleGetFromAmdm}
            // style={{ backgroundColor: segment.color }}
            title="Get from AMDM"
          >
            <MdCheck style={{ fontSize: '1em', color: 'white' }} />
          </div>
        );
      }
    }

    return;
  };

  const handleIsSmart = () => {
    if (
      loading ||
      (segment.smartBuilder?.prompt && segment.smartBuilder.recommanderStatus !== 'SUCCESS') ||
      (segment.smartBuilder?.runId && segment.smartBuilder.recommanderStatus !== 'SUCCESS') ||
      segment.smartBuilder?.audienceBuilderStatus === 'RUNNING' ||
      !setSelectedStage ||
      !setCurrentStage
    )
      return;

    setSelectedStage((prev: 'Definition' | 'Matching' | 'Smart Builder') => {
      if (prev === 'Definition') {
        setCurrentStage('Smart Builder');
        return 'Smart Builder';
      } else {
        setCurrentStage('Definition');
        return 'Definition';
      }
    });

    dispatch(
      segmentSlice.actions.setIsSmart({
        id: segment.id,
      }),
    );
  };
  return (
    <>
      {Object.keys(segment).length > 0 && (
        <div className="editor-header">
          <SaveAudienceModal segment={segment} />
          <GetAudienceModal segment={segment} />
          <UKPanelistAlert isOpen={ukPanelistAlertOpen} setIsOpen={setUkPanelistAlertOpen} />
          <button
            className="btn btn-outline-primary btn--sm"
            onClick={() => {
              navigate(urlBack);
              dispatch(segmentEditionReset());
            }}
          >
            <IconArrowLeft className="c-icon-stroke c-icon--20" />
            <span>Back</span>
          </button>
          <span className="segment-name">
            {segment.name}
            {segment.uiMeta.modified && ' *'}
          </span>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
          >
            <div className="editor-header__actions">
              {/* {extractPath(location.pathname) !== 'cluster-builder' &&
                extractPath(location.pathname) !== '/cluster-editor' && (
                  <button className="btn btn-outline-primary btn--sm" onClick={() => handleIsSmart()}>
                    <FormOutlined className="c-icon-stroke c-icon--20 edit-icon" />
                    <span>{segment?.smartBuilder?.active ? 'Manual' : 'Smart Builder'}</span>
                  </button>
                )} */}

              {(!segment.ownerId && emptySegment) || (segment.uiMeta.modified && !emptySegment) ? (
                <div
                  className="hawaii-icon-button"
                  onClick={
                    segment.name === ''
                      ? () => setErrMsg('Please provide a name for your audience before saving it.')
                      : segment.uiMeta.isComputing
                      ? undefined
                      : handleSaveAudience
                  }
                  style={{ backgroundColor: segment.uiMeta.isComputing ? 'gray' : '' }}
                  title="Save"
                >
                  <MdSave className="c-icon--20" />
                </div>
              ) : null}

              {segment.ownerId ? (
                (user.ownerId !== segment.ownerId && !emptySegment) || (!segment.uiMeta.modified && !emptySegment) ? (
                  <div
                    className="hawaii-icon-button"
                    onClick={segment.uiMeta.isComputing ? undefined : handleCopyAudience}
                    style={{ backgroundColor: segment.uiMeta.isComputing ? 'gray' : '' }}
                    title="Copy"
                  >
                    <MdContentCopy style={{ fontSize: '1em', color: 'white' }} />
                  </div>
                ) : null
              ) : null}

              {getAmdmButton()}

              {/* TODO: remove this comment to enabe button */}
              {/* {handleSettings && (
              <div className="hawaii-icon-button" title="Settings" onClick={() => handleSettings()}>
              <SettingOutlined className="c-icon-stroke c-icon--20" />
              </div>
              )} */}

              <Popover
                placement="bottom"
                isOpen={openPropertiesDial}
                openClose={(state) => {
                  setOpenPropertiesDial(state);
                  setErrMsg('');
                }}
                render={() => (
                  <div className="hawaii-control editor-menu">
                    <EditionPopup
                      onValidation={handleEdit}
                      currentName={segment.name}
                      currentColor={segment.color}
                      errMsg={errMsg}
                    />
                  </div>
                )}
              >
                <div className="hawaii-icon-button" title="Edit">
                  <IconEdit className="c-icon-stroke c-icon--20" />
                </div>
              </Popover>
            </div>
            <p
              style={{
                display: !openPropertiesDial && errMsg ? 'inline-block' : 'none',
                color: 'red',
                paddingRight: '0.5rem',
                paddingTop: '0.3rem',
              }}
            >
              {errMsg}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SegmentEditorHeader;
