import { FC } from 'react';
import './HomeCards.less';
import { IconArrowRight } from '../../../Svg/HavasIcons';

import _ from 'lodash';

const HomeCards: FC = () => {
  const items = [
    {
      id: 1,
      title: 'Segment Builder',
      description: 'Build Audiences and compare them against thousands of variables.',
      url: '/client/segment-builder/:country',
    },
    {
      id: 2,
      title: 'Cross Country Analysis',
      description: 'Create common Audiences across several countries at once, and analyze them against key variables.',
      url: '/client/cross-country-analysis/:countries',
    },
    {
      id: 3,
      title: 'Propensity',
      description: 'Divide consumers based on their likelihood of buying / subscribing to a specific brand.',
      url: '/client/propensity/:country',
    },
    {
      id: 4,
      title: 'Cluster Builder',
      subTitle: 'Beta Version',
      description: 'Create Audiences by grouping people who have similar behavior among a selection of attributes.',
      url: '/client/cluster-builder/:country',
    },
  ];

  return (
    <div className="grid">
      {items.map((item) => (
        <a className="grid-card" href={item.url} key={item.id}>
          <h3 className="grid-card__title">{item.title}</h3>
          <h4 className="grid-card__sub_title">{item.subTitle}</h4>
          <p className="grid-card__description">{item.description}</p>
          <div className="grid-card__footer">
            <div className="grid-card__action">
              <div className="grid-card__action-animate">
                <IconArrowRight className="c-icon-stroke" />
                <IconArrowRight className="c-icon-stroke" />
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default HomeCards;
