import { FC, useContext, useState, useEffect } from 'react';
import { SegmentTree } from '../../Common/SegmentCard/SegmentTree/SegmentTree';
import ResponsesSelector from '../../MetaComponents/VariablesDrawer/ResponsesSelector';
import VariableSelector from '../../MetaComponents/VariablesDrawer/VariableSelector';
import { useDispatch, useSelector } from 'react-redux';
import Color from 'color';
import segmentSlice from '../../store/segmentSlice';
import { updateSegmentPopulationThunk } from '../../store/thunk/updateSegmentPopulation';
import { findCriteriaById } from '../../../core/segment/helper';
import { FirstLevelCategory } from '../../Common/VariableSelector/CategoriesSelector';
import { GlobalState } from '../../store/global';
import { VariablesSelectionServiceInterface } from '../../../core/variablesSelection';
import { PanelCriteria, ResponseInfos, VariableInfos, SegmentWithUiMeta } from 'hawaii';
import UJContext from './UJContext';
import Factory from '../../../core/factory';

interface Props {
  disabled: boolean;
}

const DefinitionStage: FC<Props> = ({ disabled }) => {
  const handleRemoveCriteria = (criteriaId: string) => {
    if (!segment.uiMeta.disabled) {
      const criteria = segment.criterias.criterias.find((crit) => crit.id === criteriaId) as PanelCriteria;
      dispatch(segmentSlice.actions.segmentRemoveCriteria({ id: segment.id, criteriaId }));
      dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));

      if (segment.smartBuilder?.active && criteria) {
        const id = criteria.variable.variable_id;
        dispatch(segmentSlice.actions.smartRemoveSelectedCriteria({ id: segment.id, criteriaId: id }));
      }
    }
  };

  const handleSelectCriteria = (criteriaId: string | null, operator: string) => {
    const criteria = criteriaId ? (findCriteriaById(segment, criteriaId) as PanelCriteria) : null;

    setUpdatingSegment(segment);
    setUpdatingCriteria(criteria);
    setUpdatingOperator(operator);
    setVariableSelectorOpen(true);
  };

  const handleSelectResponse = (criteriaId: string) => {
    const criteria = findCriteriaById(segment, criteriaId) as PanelCriteria;

    setUpdatingSegment(segment);
    setUpdatingCriteria(criteria);
    setResponseSelectorOpen(true);
  };

  const handleRemoveResponse = (criteriaId: string, response: ResponseInfos) => {
    dispatch(segmentSlice.actions.removeResponse({ segmentId: segment.id, criteriaId, responseValue: response.value }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));
  };

  const handleUpdateZIndex = (criteriaId: string, zIndex: number) => {
    dispatch(segmentSlice.actions.updateZIndex({ segmentId: segment.id, criteriaId, zIndex }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));
  };

  const handleChangeCriteriaRule = (segment: SegmentWithUiMeta, criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => {
    dispatch(segmentSlice.actions.updateRule({ segmentId: segment.id, criteriaId, rule }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));
  };

  const handleVariableValidation = (selectedVariable: VariableInfos, selectedResponses: ResponseInfos[]) => {
    dispatch(
      segmentSlice.actions.segmentAddCriteria({
        id: segment.id,
        criteriaId: updatingCriteria === null ? updatingCriteria : updatingCriteria.id,
        selectedVariable,
        selectedResponses,
        operator: updatingOperator,
      }),
    );
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));
    setVariableSelectorOpen(false);
  };

  const searchCallback = async (regex: string): Promise<VariableInfos[]> => {
    return await variableService.getVariablesFromInput(regex, segment.countryIsos, selectedLanguage, segment.dataScope);
  };

  const categoryTreeProvider = (path: string[]): Promise<FirstLevelCategory[]> => {
    return categoryService.get(path, segment.countryIsos, selectedLanguage, segment.dataScope);
  };

  const responseSelectorValidation = (selectedResponses: ResponseInfos[]) => {
    dispatch(
      segmentSlice.actions.updateResponse({
        segmentId: updatingSegment!.id,
        criteriaId: updatingCriteria!.id,
        selectedResponses,
      }),
    );
    dispatch(updateSegmentPopulationThunk({ segmentId: updatingSegment!.id }));
    setResponseSelectorOpen(false);
  };

  const dispatch = useDispatch();

  const { segment } = useContext(UJContext);
  const categoryService = Factory().getCategoryService();
  const variableService = Factory().getVariableSelectionService();

  const [updatingSegment, setUpdatingSegment] = useState<SegmentWithUiMeta | undefined>(undefined);
  const [updatingCriteria, setUpdatingCriteria] = useState<PanelCriteria | null>(null);

  const [updatingOperator, setUpdatingOperator] = useState<string>('AND');
  const [variableSelectorOpen, setVariableSelectorOpen] = useState<boolean>(false);
  const [responseSelectorOpen, setResponseSelectorOpen] = useState<boolean>(false);

  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);

  useEffect(() => {
    if (!segment) return;
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id, retrieveSeries: false }));
  }, [!segment.uiMeta.marketSize]);

  return (
    <>
      <div className="editor-content-border">
        {segment.smartBuilder?.active ? (
          <div>
            <p>Select the variable to be used in the definition of the Audience</p>
          </div>
        ) : null}
        <div className="editor-content hawaii-thin-scrollbar">
          <SegmentTree
            criterias={segment.criterias}
            color={segment.color}
            disabled={segment.uiMeta.disabled || disabled}
            addOperatorButton={false}
            removeCriteriaAction={handleRemoveCriteria}
            addCriteriaAction={handleSelectCriteria}
            removeResponsesAction={handleRemoveResponse}
            updateResponsesAction={handleSelectResponse}
            updateZIndexAction={handleUpdateZIndex}
            changeCriteriaRuleAction={(criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') =>
              handleChangeCriteriaRule(segment, criteriaId, rule)
            }
          />
          {disabled || (
            <>
              <button
                className="operator"
                // style={{ backgroundColor: segment.color, marginRight: '1em', borderColor: segment.color }}
                onClick={() => handleSelectCriteria(null, segment.criterias.operator)}
              >
                {segment.criterias.operator}...
              </button>
              <button
                className="operator"
                // style={{ backgroundColor: segment.color, borderColor: segment.color }}
                onClick={() => handleSelectCriteria(null, segment.criterias.operator === 'AND' ? 'OR' : 'AND')}
              >
                {segment.criterias.operator === 'AND' ? 'OR' : 'AND'}
              </button>
            </>
          )}
          {segment.smartBuilder?.active ? (
            <div className="explanatory-copy">
              <p>Name your audience and save it</p>
            </div>
          ) : null}
        </div>
      </div>

      {updatingCriteria !== undefined && variableSelectorOpen && (
        <VariableSelector
          color={segment.color}
          categoryTreeProvider={categoryTreeProvider}
          title="Choose a variable for the audience"
          subtitle="With a selection of variables and responses"
          validationCaption="Add"
          visible={true}
          variableService={variableService}
          countries={segment.countryIsos}
          languageCode={selectedLanguage}
          dataScope={segment.dataScope}
          onClose={(variableService: VariablesSelectionServiceInterface) => {
            variableService.abort();
            setVariableSelectorOpen(false);
          }}
          searchCallback={searchCallback}
          validateVariableChoice={handleVariableValidation}
        />
      )}
      {responseSelectorOpen &&
        updatingSegment !== undefined &&
        updatingCriteria !== undefined &&
        updatingCriteria !== null && (
          <ResponsesSelector
            color={updatingSegment.color}
            onClose={() => {
              setResponseSelectorOpen(false);
            }}
            selectedResponses={updatingCriteria.responses}
            selectedVariable={updatingCriteria.variable}
            validateUpdateChoice={responseSelectorValidation}
            variableService={variableService}
            visible={true}
            countries={segment.countryIsos}
            languageCode={selectedLanguage}
            dataScope={updatingSegment.dataScope}
          />
        )}
    </>
  );
};

export default DefinitionStage;
