import { FC } from 'react';
import './Footer.less';

const Footer: FC = () => {
  return (
    <div className="l-footer">
      <div className="l-footer__wrapper">
        <p>
          All rights reserved. -{' '}
          <a href="https://jira.havas.com/pm/servicedesk/customer/portal/260/group/473" target="_blank">
            Converged Intelligence Support.
          </a>
        </p>
        <p>© 2024 HAVAS</p>
      </div>
    </div>
  );
};

export default Footer;
