export const API_VERSION_SEGMENT = "3.0";

export type DataScope = "Harmonized" | "Original";

export type VariableUse = "INCLUDE" | "EXCLUDE";

export interface PanelCriteria<TV = VariableInfos, TRI = ResponseInfos> {
  id: string;
  variable: TV;
  responses: TRI[];
  rule: "INCLUDE" | "EXCLUDE";
}

export interface PanelCriteriaStack<TVI = VariableInfos, TRI = ResponseInfos> {
  id: string;
  criterias: (PanelCriteria<TVI, TRI> | PanelCriteriaStack<TVI, TRI>)[];
  operator: string;
  zIndex: number;
}

export interface VariableInfos {
  variable_id: string;
  variable_name: string;
  variable_text: string;
  response_id: string;
  isparent: 0 | 1;
}

export interface ResponseInfos {
  label: string;
  value: string;
  variableId: string;
  parentResponseId?: string;
}

export enum SegmentOrigin {
  "SEGMENT_BUILDER",
  "CLUSTER_BUILDER",
  "CROSS_COUNTRY",
}

export interface SegmentCommonProperties<
  TVI = VariableInfos,
  TRI = ResponseInfos
> {
  id: string;
  publicId: string;
  ownerId?: string;
  countryIsos: string[];
  shared: boolean;
  dataScope: DataScope;
  name: string;
  color: string;
  criterias: PanelCriteriaStack<TVI, TRI>;
  version: string;
  criteriaHash: string;
  activationStatus: string | undefined;
  creationDate?: Date;
  origin?: SegmentOrigin;
  isSmart?: boolean | undefined;
  smartBuilder?: {
    active: boolean;
    level: string;
    prompt: string;
    runId: string;
    recomannderRunId: string;
    sentimentStatus: string;
    recommanderStatus: string;
    audienceBuilderStatus: string;
    audienceBuilderRunIds: string[];
    selectedSubAudiencies: { index: number; options: number[] }[];
    savedSubAudiencies: { index: number; options: number[] }[];
    settings: {
      MAX_ALT_SEGMENT: number;
      MAX_ALT_QUESTION: number;
      RESULT_VALIDATION: string;
      VALIDATION_SCORE: string;
    };
    recommander: {
      title: string;
      options: {
        value: string;
        label: string;
      }[];
    }[];
    sentiments: {
      negative: string[];
      positive: string[];
    };
  };
  amdmInfos:
    | {
        id: string;
        audienceVersionId: string;
        audienceRecordId: string;
      }
    | undefined;
}

export interface SegmentUiMeta {
  isComputing: boolean;
  stats: number | null;
  totalRespondents: number | null;
  marketSize: number | null;
  disabled: boolean;
  // modified in the front and not persisted in backend
  modified: boolean;
  // not relevant (for exemple : if segment population is low)
  irrelevant: boolean;
}

export interface SegmentWithUiMeta extends SegmentCommonProperties {
  uiMeta: SegmentUiMeta;
}

export interface SegmentWithPartialUiMeta extends SegmentCommonProperties {
  uiMeta: Partial<SegmentUiMeta>;
}
