import { random } from 'lodash';
import Color from 'color';

import { isACriteriaStack } from './criteria';
import { API_VERSION_SEGMENT, PanelCriteria, PanelCriteriaStack, SegmentWithUiMeta } from 'hawaii';

let cursorColor = random(360);

// **********************************************************

export const segmentGenerateId = (): string => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * 36));
  }
  return `_${result}`;
};

/**
 * Return a flat version of all segment criterias
 * If includeStack is true, the CriteriaStack will be included
 *
 * @returns
 */
export const flat = (segment: SegmentWithUiMeta, includeStack = false): (PanelCriteria | PanelCriteriaStack)[] => {
  const flatStack = (criteria: PanelCriteria | PanelCriteriaStack): (PanelCriteria | PanelCriteriaStack)[] => {
    return !isACriteriaStack(criteria)
      ? [criteria]
      : includeStack
      ? [criteria, ...criteria.criterias.flatMap(flatStack)]
      : criteria.criterias.flatMap(flatStack);
  };
  if (segment.criterias === null) {
    return [];
  }
  const flatten = isACriteriaStack(segment.criterias)
    ? segment.criterias.criterias.flatMap(flatStack)
    : [segment.criterias];
  return includeStack ? [segment.criterias, ...flatten] : flatten;
};

/**
 * Return the number of variable in a PanelCriteria, recursively
 *
 * @param criteriaStack
 * @returns
 */
const getCriteriaLength = (segment: SegmentWithUiMeta): number => {
  return flat(segment).length;
};

/**
 * Return the PanelCriteria | PanelCriteriaStack based on its id
 * Return undefined if it doesn't exists
 *
 * @param id
 */
export const findCriteriaById = (
  segment: SegmentWithUiMeta,
  id: string,
): PanelCriteria | PanelCriteriaStack | undefined => {
  return flat(segment, true).find((criteria: PanelCriteria | PanelCriteriaStack) => criteria.id === id);
};

/**
 * recursively set ids for any part of an entire PanelCriteriaStack
 *
 * @param criterias
 */
const provideId = (criterias: PanelCriteriaStack | PanelCriteria): PanelCriteriaStack | PanelCriteria => {
  if (criterias.id === undefined) {
    criterias.id = segmentGenerateId();
  }

  if (isACriteriaStack(criterias)) {
    criterias.criterias = criterias.criterias.map(provideId);
  }
  return criterias;
};

/**
 * provide a new empty criteriaStack
 * @returns
 */
const generateCriteriaStack = () =>
  ({
    id: segmentGenerateId(),
    criterias: [],
    operator: 'AND',
    zIndex: 0,
  } as PanelCriteriaStack);

export const initialSegmentProperties = ({
  id,
  publicId,
  ownerId,
  version,
  criteriaHash,
  countryIsos,
  shared,
  name,
  color,
  criterias,
  dataScope,
  activationStatus,
  amdmInfos,
  uiMeta,
  origin,
  isSmart,
}: Partial<SegmentWithUiMeta>): SegmentWithUiMeta => {
  return {
    id: id !== undefined ? id : segmentGenerateId(),
    color: color !== undefined ? color : Color.hsl((cursorColor += 100), 70, 50).hex(),
    countryIsos: countryIsos !== undefined ? countryIsos : ['fr'],
    dataScope: dataScope !== undefined ? dataScope : 'Original',
    name: name || '',
    ownerId: ownerId || '',
    shared: shared || false,
    smartBuilder: {
      active: (isSmart as boolean) || false,
      level: '0',
      prompt: '',
      runId: '',
      recomannderRunId: '',
      sentimentStatus: '',
      recommanderStatus: '',
      audienceBuilderStatus: '',
      audienceBuilderRunIds: [],
      selectedSubAudiencies: [],
      savedSubAudiencies: [],
      settings: {
        MAX_ALT_SEGMENT: 0,
        MAX_ALT_QUESTION: 0,
        RESULT_VALIDATION: '',
        VALIDATION_SCORE: '',
      },
      recommander: [
        {
          title: '',
          options: [],
        },
      ],
      sentiments: {
        negative: [],
        positive: [],
      },
    },
    uiMeta: {
      isComputing: false,
      stats: uiMeta?.stats || null,
      totalRespondents: uiMeta?.totalRespondents || null,
      marketSize: uiMeta?.marketSize || null,
      disabled: false,
      modified: false,
      irrelevant: false,
    },
    criterias: criterias === undefined ? generateCriteriaStack() : (provideId(criterias) as PanelCriteriaStack),
    publicId: publicId !== undefined ? publicId : '',
    version: version !== undefined ? version : API_VERSION_SEGMENT,
    criteriaHash: criteriaHash !== undefined ? criteriaHash : '',
    activationStatus,
    amdmInfos,
    origin: origin === undefined ? undefined : origin,
  };
};

/**
 * Return the parent of a criteria based on its id
 *
 * @param id
 * @returns
 */
const getParentCriteria = (segment: SegmentWithUiMeta, id: string): PanelCriteriaStack | undefined =>
  flat(segment, true)
    // parent must be a PanelCriteriaStack
    .filter(isACriteriaStack)
    .find(
      (criteria: PanelCriteria | PanelCriteriaStack) =>
        // cannot be something else than a PanelCriteriaStack
        (criteria as PanelCriteriaStack).criterias.find(
          (panel: PanelCriteria | PanelCriteriaStack) => panel.id === id,
        ) !== undefined,
    );

/**
 * Replace a specific reference (targeted by its id) with a new one (id may be different)
 * throw an exception if id doesn't exists
 * throw an exception if attempting to put a non PanelCriteriaStack at top level
 *
 * @param id
 * @param criteria
 */
export const replaceCriteria = (
  s: SegmentWithUiMeta,
  id: string,
  criteria: PanelCriteria | PanelCriteriaStack,
): SegmentWithUiMeta => {
  const result = { ...s };

  if (result.criterias === null) {
    return result;
  }

  if (result.criterias.id === id) {
    if (!isACriteriaStack(criteria)) {
      throw 'Segment top level criteria must be an instance of PanelCriteriaStack';
    }

    result.criterias = criteria;
  } else {
    const parent = getParentCriteria(result, id);
    if (parent === undefined) {
      throw 'Cannot found id in segment';
    }

    const position = parent.criterias.findIndex((criteria: PanelCriteria | PanelCriteriaStack) => criteria.id === id);
    parent.criterias[position] = criteria;
  }

  return result;
};

/**
 * Add a criteria to the stack identified by id.
 * If id is null, add it at top level
 * If id is not found, does nothing
 *
 * @param id
 * @param panelCriteria
 */
export const addCriteria = (
  segment: SegmentWithUiMeta,
  id: string | null,
  operand: string,
  criteria: PanelCriteria,
): SegmentWithUiMeta => {
  const result = { ...segment };

  if (criteria.id === undefined) {
    criteria.id = segmentGenerateId();
  }

  // if id is null : target the top level criteriaStack
  if (id === null) {
    id = result.criterias.id;
  }
  const targetCriteriaStack = findCriteriaById(result, id);

  // id doesn´t match to any target ?
  if (targetCriteriaStack === undefined) {
    return result;
  }

  if (!isACriteriaStack(targetCriteriaStack)) {
    const parent = getParentCriteria(result, targetCriteriaStack.id);

    // no parent - typically impossible
    if (parent === undefined) {
      throw 'Unexpected exception : orphan criteria found in segment';
    }

    // replace the child with a new stack
    replaceCriteria(result, id, {
      id: segmentGenerateId(),
      criterias: [targetCriteriaStack, criteria],
      operator: operand,
      zIndex: 0,
    });

    return result;
  }

  return targetCriteriaStack.operator === operand || targetCriteriaStack.criterias.length < 2
    ? replaceCriteria(result, targetCriteriaStack.id, {
        ...targetCriteriaStack,
        criterias: [...targetCriteriaStack.criterias, criteria],
        operator: operand,
      })
    : // not the same : create a higher level
      replaceCriteria(result, id, {
        id: segmentGenerateId(),
        criterias: [targetCriteriaStack, criteria],
        operator: operand,
        zIndex: 0,
      });
};

/**
 * Delete a segment criteria based on its id
 *
 * @param id
 */
export const removeCriteria = (segment: SegmentWithUiMeta, id: string): SegmentWithUiMeta => {
  const result = { ...segment };
  const parent = getParentCriteria(result, id);

  if (parent === undefined) {
    // trying to remove top level (or id unfound)
    return result;
  }
  // remove from the list
  parent.criterias = parent.criterias.filter((criteria) => criteria.id !== id);

  // no parent with only one child
  const parentLength = parent.criterias.length;
  if (parentLength === 1) {
    const child = parent.criterias[0];
    if (parent.id === result.criterias.id && !isACriteriaStack(child)) {
      return result;
    }

    return replaceCriteria(result, parent.id, child);
  } else {
    if (parent.zIndex > parentLength) {
      parent.zIndex = parentLength;
    }
  }

  return result;
};

/**
 * return the total variable number presents in the segment definition
 */
export const getSegmentVariablesNumber = (segment: SegmentWithUiMeta): number => {
  return getCriteriaLength(segment);
};

/**
 * Return a description of the segment criteria in a human readable way
 *
 * @param segment
 * @returns
 */
export const humanReadable = (segment: SegmentWithUiMeta): string => {
  const translateCriteria = (c: PanelCriteria): string =>
    `"${c.variable.variable_text}" ["${c.responses.map((response) => response.label).join('","')}"]`;

  const translateCriteriaStack = (c: PanelCriteriaStack, withParenthesis = false): string =>
    `${withParenthesis ? '(' : ''} ${c.criterias
      .map((criteria) =>
        isACriteriaStack(criteria)
          ? translateCriteriaStack(criteria, true)
          : `${criteria.rule === 'EXCLUDE' ? 'NOT ' : ''}( ${translateCriteria(criteria)} )`,
      )
      .join(` ${c.operator} `)} ${withParenthesis ? ')' : ''}`;

  return translateCriteriaStack(segment.criterias);
};
