import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';

export interface SECMDMCountry {
  name: string;
  id: number;
  tableId: number;
  translationId: string;
  iso: string;
}
export interface AMDMMxInfos {
  id: number;
  name: string;
}

export interface AMDMClientsInfos extends AMDMMxInfos {
  isPitch: boolean;
}

export interface CheckMDMUserInfo {
  isoAlpha2: string;
  marketId: number;
  userExist: boolean;
  ownerId?: string;
}

export interface SecmdmServiceInterface {
  abort: () => void;
  listAgencies: (countriesId: number[]) => Promise<AMDMMxInfos[]>;
  listClients: () => Promise<AMDMClientsInfos[]>;
  checkUser: () => Promise<CheckMDMUserInfo>;
  listCountries: () => Promise<SECMDMCountry[]>;
}

export interface AmdmUpn {
  encryptedUpn: string;
  ownerUpn: string;
}

const SecmdmService = (backend: BackendFacadeInterface): SecmdmServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    checkUser: async (): Promise<CheckMDMUserInfo> => {
      return await backend.get<CheckMDMUserInfo>(`/v1/secmdm/check/user`);
    },

    listAgencies: async (countriesId: number[]): Promise<AMDMMxInfos[]> => {
      return await backend.post<AMDMMxInfos[]>(`/v1/secmdm/agencies`, countriesId);
    },

    listClients: async (): Promise<AMDMClientsInfos[]> => {
      return await backend.get<AMDMClientsInfos[]>(`/v1/secmdm/clients`);
    },

    listCountries: async (): Promise<SECMDMCountry[]> => {
      return await backend.get<SECMDMCountry[]>(`/v1/secmdm/countries`);
    },
  };
};

export interface SecmdmServiceFactoryInterface {
  get: () => SecmdmServiceInterface;
}

const SecmdmServiceFactory = (backendFactory: BackendFacadeFactoryInterface): SecmdmServiceFactoryInterface => ({
  get: () => SecmdmService(backendFactory.get()),
});

export default SecmdmServiceFactory;
