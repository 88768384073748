import { Dispatch } from 'redux';
import axios from 'axios';
import {
  HYDRATE_VARIABLES_HISTORY,
  HYDRATE_CLEANUP_VARIABLES,
  HYDRATE_SAVE_VARIABLES_HISTORY,
  HYDRATE_SELECTED_VARIABLE,
} from './constants';
import { config } from '../../../../core/config';
import { msalInstance } from '../../../..';
import { AccountInfo } from '@azure/msal-browser';

export interface IVariablesHistory {
  variablesDetails: {
    countryId: number;
    selectedVariable: {
      variable_id: string;
      variable_name: string;
      variable_text: string;
      isparent: 0 | 1;
      category_1: string;
      category_2: string;
      category_3: string;
      response_id: string;
    };
    responsePanel: {
      label: string;
      value: string;
      variableId: string;
    }[];
    dataScope: string;
    _id: string;
  };
  crossResults: {
    countryIsos: string[];
    variableId: string;
    responseIds: string[];
  };
}

const BASE_URL = config.api.baseUrl as string;

const addBearerTokenInHeaders = async (): Promise<void> => {
  try {
    const userAccount = msalInstance.getActiveAccount() as AccountInfo;
    const authentication = await msalInstance.acquireTokenSilent({
      scopes: ['user.read'],
      account: userAccount,
      forceRefresh: true,
    });
    axios.defaults.headers.common['Authorization'] = `Bearer ${authentication.idToken}`;
  } catch (error) {
    await msalInstance.acquireTokenRedirect({
      scopes: ['user.read'],
    });
    console.error('Unable to get token from header !');
  }
};

export const getVariablesHistory = () => async (dispatch: Dispatch) => {
  const abortController = new AbortController();
  await addBearerTokenInHeaders();
  // eslint-disable-next-line no-useless-catch
  try {
    const variablesHistory = await axios.get(`${BASE_URL}/v1/variable_details/getVariablesHistory`, {
      withCredentials: true,
      signal: abortController.signal,
    });
    dispatch({
      type: HYDRATE_VARIABLES_HISTORY,
      payload: variablesHistory,
    });
  } catch (error) {
    throw error;
  }
};

export const getVariablesHistoryViaCountry = (countryIso: string) => async (dispatch: Dispatch) => {
  const abortController = new AbortController();
  await addBearerTokenInHeaders();
  // eslint-disable-next-line no-useless-catch
  try {
    const variablesHistory = await axios.get(`${BASE_URL}/v1/variable_details/variable-history-country/${countryIso}`, {
      withCredentials: true,
      signal: abortController.signal,
    });
    dispatch({
      type: HYDRATE_VARIABLES_HISTORY,
      payload: variablesHistory,
    });
  } catch (error) {
    throw error;
  }
};

export const saveVariablesHistory = (variables: IVariablesHistory) => async (dispatch: Dispatch) => {
  const abortController = new AbortController();
  await addBearerTokenInHeaders();
  // eslint-disable-next-line no-useless-catch
  try {
    const variablesHistory = await axios.post(`${BASE_URL}/v1/variable_details/saveVariablesHistory`, variables, {
      withCredentials: true,
      signal: abortController.signal,
    });

    dispatch({
      type: HYDRATE_CLEANUP_VARIABLES,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload: variablesHistory.data.variablesDetails.selectedVariable.variable_id as string,
    });

    return dispatch({
      type: HYDRATE_SAVE_VARIABLES_HISTORY,
      payload: variablesHistory,
    });
  } catch (error) {
    throw error;
  }
};

export const selectVariablesHistory = (variableId: string) => (dispatch: Dispatch) => {
  return dispatch({
    type: HYDRATE_SELECTED_VARIABLE,
    payload: variableId,
  });
};

export const deleteVariablesHistory = () => async (dispatch: Dispatch) => {
  const abortController = new AbortController();
  await addBearerTokenInHeaders();
  // eslint-disable-next-line no-useless-catch
  try {
    const deleteStatus = await axios.post(`${BASE_URL}/v1/variable_details/deleteVariablesHistory`, {
      withCredentials: true,
      signal: abortController.signal,
    });

    if (deleteStatus) {
      return dispatch({
        type: HYDRATE_VARIABLES_HISTORY,
        payload: { data: [] },
      });
    } else return;
  } catch (error) {
    throw error;
  }
};

export const cleanUpVariablesHistory = (variableId: string) => (dispatch: Dispatch) => {
  return dispatch({
    type: HYDRATE_CLEANUP_VARIABLES,
    payload: variableId,
  });
};
