import { Serie, SerieTag, TotalValue } from './series/interface';
import {
  serieGlobalTagsMatch,
  seriePermutationsOfValues,
  serieRatio,
  serieReduce,
  serieTagFilter,
} from './series/serie';
import { get, isNumber } from 'lodash';

export const crossResultsFilterBySelectedResponse = (selection: Record<string, string[]>, series: Serie[]): Serie[] =>
  series.map((serie): Serie => {
    const responses = selection[serie.globalTags.variableId as string] || [];
    return serieTagFilter((tag: SerieTag) => responses.includes(tag.responseId as string))(serie);
  });

export const crossResulsFilterByCountry = (countryCode: number, series: Serie[]): Serie[] =>
  series.filter((serie) => serie.globalTags.countryCode === countryCode);

/**
 * Return the unweighted part of serie (the global-tagged <isWeighted=false>)
 * A <label="Unweighted respondents"> global tag is added to the serie
 *
 * @param series
 * @returns
 */
export const unweightedResponseCountSeries = (series: Serie[]): (() => Serie[]) => {
  console.log({ series });
  return () =>
    series
      .filter((serie) => serieGlobalTagsMatch({ isWeighted: false })(serie))
      .map((serie) => ({
        ...serie,
        values: serie.tags.map((tag) => {
          return serie.totalValues.find(({ variableId }) => variableId === tag.responseId)?.respCount as number;
        }),
        globalTags: { ...serie.globalTags, label: 'Unweighted respondents' },
      }));
};

/**
 * Return the percentages of respondent based on "distribution base comparaison"
 * A <label="Distribution of the respondents"> global tag is added to the serie
 *
 * @param series
 * @returns
 */
export const audienceResponsePercentageSeries = (series: Serie[], variableDetails: Serie[]): (() => Serie[]) => {
  return () => {
    return series
      .filter((serie) => serieGlobalTagsMatch({ isWeighted: true })(serie))
      .map((serie: Serie) => {
        const vdSerie = variableDetails.find((vds) =>
          serieGlobalTagsMatch({
            variableId: serie.globalTags.variableId,
            countryCode: serie.globalTags.countryCode,
          })(vds),
        );

        if (!vdSerie) {
          throw Error('Stale variableDetail!');
        }

        const selectedResponses = serie.tags.map((tag) => tag['responseId']);
        const filteredVdSerie = serieTagFilter((tag: SerieTag) => selectedResponses.includes(tag.responseId as string))(
          vdSerie,
        );

        const isParent = filteredVdSerie.globalTags.isParent === 1;

        const distributionBaseComparaison = serie.globalTags.distributionBaseComparaison as number;

        const res_serie = serieReduce(serie, (n: number) => {
          return Number(((n / distributionBaseComparaison) * 100).toFixed(1));
        });

        if (isParent) {
          const newValues = serie.tags.map((tag) => {
            const values = serie.totalValues.find(({ variableId }) => variableId === tag.responseId) as TotalValue;

            return Number(((values.weiCount / values.totalWeiCount) * 100).toFixed(1));
          });

          return {
            ...serie,
            values: newValues,
            totalValues: [],
            globalTags: {
              ...serie.globalTags,
              label: 'Distribution of the respondents',
            },
          };
        }

        return {
          ...serie,
          values: res_serie.values,
          globalTags: {
            ...serie.globalTags,
            label: 'Distribution of the respondents',
          },
        };
      });
  };
};

/**
 * Return values resized regarding the population
 *
 * @param   {number}  population                    population crowd
 * @param   {Serie}   serie                         Base serie
 *
 * @return  {(serie: Serie) => Serie}               Serie resizor callback
 */
export const marketResize = (population: number): ((serie: Serie) => Serie) => {
  return (serie: Serie): Serie => {
    const marketSizeBaseComparaison = serie.globalTags.marketSizeBaseComparaison as number;
    if (marketSizeBaseComparaison === undefined) {
      throw `marketSizeBaseComparaison is undefined for ${serie.globalTags.name as string}/${
        serie.globalTags.variableName as string
      }`;
    }
    return serieRatio(serie, population / marketSizeBaseComparaison);
  };
};

/**
 * Total market size serie
 * A <label="Total market size"> global tag is added to the serie
 *
 * @param population
 * @param series
 * @returns
 */
export const marketSizedSeries = (population: number, series: Serie[], variableDetails: Serie[]): (() => Serie[]) => {
  return () => {
    return series
      .filter((serie) => serieGlobalTagsMatch({ isWeighted: true })(serie))
      .map((serie: Serie) => {
        const vdSerie = variableDetails.find((vds) =>
          serieGlobalTagsMatch({
            variableId: serie.globalTags.variableId,
            countryCode: serie.globalTags.countryCode,
          })(vds),
        );

        if (!vdSerie) {
          throw Error('Stale variableDetail !');
        }
        const selectedResponses = serie.tags.map((tag) => tag['responseId']);
        const filteredVdSerie = serieTagFilter((tag: SerieTag) => selectedResponses.includes(tag.responseId as string))(
          vdSerie,
        );

        const isParent = filteredVdSerie.globalTags.isParent === 1;

        const marketSizeBaseComparaison = get(serie, 'globalTags.marketSizeBaseComparaison', 1) as number;

        const resizedSerie = serieRatio(serie, population / marketSizeBaseComparaison);

        if (isParent) {
          const newValues = serie.tags.map((tag) => {
            const values = serie.totalValues.find(({ variableId }) => variableId === tag.responseId) as TotalValue;

            return Number(((population / values.totalWeiCount) * values.weiCount).toFixed(1));
          });

          return {
            ...serie,
            values: newValues,
            globalTags: {
              ...serie.globalTags,
              label: 'Total market size',
            },
          };
        }

        return {
          ...serie,
          values: resizedSerie.values,
          globalTags: {
            ...serie.globalTags,
            label: 'Total market size',
          },
        };
      });
  };
};

const getPercentage = (value: number, distributionBaseComparaison: number) =>
  isNumber(value) && isNumber(distributionBaseComparaison) && distributionBaseComparaison !== 0
    ? Number(((value / distributionBaseComparaison) * 100).toFixed(1))
    : 0;
/**
 * Index serie
 * A <label="Index"> global tag is added to the serie
 *
 * @param population
 * @param series
 * @returns
 */
export const indexSeries = (variableDetails: Serie[], series: Serie[]): (() => Serie[]) => {
  return () => {
    return series
      .filter((serie) => serieGlobalTagsMatch({ isWeighted: true })(serie))
      .map((serie: Serie) => {
        const vdSerie = variableDetails.find((vds) =>
          serieGlobalTagsMatch({
            variableId: serie.globalTags.variableId,
            countryCode: serie.globalTags.countryCode,
          })(vds),
        );

        if (!vdSerie) {
          throw Error('Stale variableDetail!');
        }

        const selectedResponses = serie.tags.map((tag) => tag['responseId']);
        const filteredVdSerie = serieTagFilter((tag: SerieTag) => selectedResponses.includes(tag.responseId as string))(
          vdSerie,
        );
        const isParent = filteredVdSerie.globalTags.isParent === 1;
        const countryTotal = (get(filteredVdSerie.globalTags, 'countryTotal', 1) as number) || 1;
        const distributionBaseComparaison = serie.globalTags.distributionBaseComparaison as number;

        const countryPercentageSeries = serieReduce(filteredVdSerie, (n: number) => {
          return Number(((n / countryTotal) * 100).toFixed(1));
        });

        const audiencePercentageSeries = serieReduce(serie, (n: number) => {
          return Number(((n / distributionBaseComparaison) * 100).toFixed(1));
        });

        const indexedSerie = serieReduce(audiencePercentageSeries, (n: number, i: number) => {
          const denominator = countryPercentageSeries.values[i] || 1; // Evitar divisiones por cero
          return Number(((n / denominator) * 100).toFixed(0));
        });
        if (isParent) {
          const newValues = serie.tags.map((tag) => {
            const baseTotal = (serie.globalTags.distributionBaseComparaison || 1) as number;
            const baseMarketsize = (serie.globalTags.marketSizeBaseComparaison || 1) as number;

            const values = serie.totalValues.find(({ variableId }) => variableId === tag.responseId) as TotalValue;

            const denominator = Number(((values.respCount / values.weiCount) * 100).toFixed(1));
            const index = Number(((values.weiCount / baseTotal) * 100).toFixed(1));

            const countryToCalculate = values.totalWeiCount;

            const countryPercentageSeries = Number(((values.weiCount / countryToCalculate) * 100).toFixed(2));
            const percentage = getPercentage(values.weiCount, values.totalWeiCount);

            return Number(((percentage / countryPercentageSeries) * 100).toFixed(0));
          });
          return {
            ...serie,
            values: newValues,
            totalValues: [],
            globalTags: {
              ...indexedSerie.globalTags,
              label: 'Index',
            },
          };
        }

        return {
          ...indexedSerie,
          globalTags: {
            ...indexedSerie.globalTags,
            label: 'Index',
          },
        };
      });
  };
};

/**
 * Return a new Serie based on the sum of :
 *
 * * values
 * * distributionBaseComparaison
 * * marketSizeBaseComparaison
 * * countryTotal
 *
 * @param series
 * @returns
 */
export const sumAll = (series: Serie[]): Serie => {
  const mergedValues: number[] = [];
  let mergedDistributionBaseComparaison = 0;
  let mergedMarketSizeBaseComparaison = 0;
  let mergedCountryTotal = 0;

  const mergedSeries = series.reduce((acc, curr) => {
    curr.values.forEach((val, i) => {
      mergedValues[i] = (mergedValues[i] || 0) + val;
    });

    mergedDistributionBaseComparaison += curr.globalTags.distributionBaseComparaison as number;
    mergedMarketSizeBaseComparaison += curr.globalTags.marketSizeBaseComparaison as number;

    if (curr.globalTags.countryTotal) {
      mergedCountryTotal += curr.globalTags.countryTotal as number;
    }

    const globalTags = {
      ...curr.globalTags,
      distributionBaseComparaison: mergedDistributionBaseComparaison,
      marketSizeBaseComparaison: mergedMarketSizeBaseComparaison,
      countryTotal: mergedCountryTotal ?? undefined,
    };

    return { ...curr, globalTags, values: mergedValues };
  }, {} as Serie);

  return mergedSeries;
};

/**
 * Regroup Series with common segmentId and variableId
 * Sum their values and global indicators
 *
 * @param series
 * @returns
 */
export const sumByCountry = (series: () => Serie[]): (() => Serie[]) => {
  return (): Serie[] => {
    if (series().length < 1) {
      return series();
    }

    const regroupedSeries = series().reduce((acc, curr) => {
      const currIncludes = acc
        .map((el) => {
          return el.some(
            (s) =>
              s.globalTags.segmentId === curr.globalTags.segmentId &&
              s.globalTags.variableId === curr.globalTags.variableId,
          );
        })
        .flat();

      if (currIncludes.length < 1 || !currIncludes.includes(true)) {
        acc.push([curr]);
      } else if (currIncludes.includes(true)) {
        const index = currIncludes.findIndex((el) => el === true);
        acc[index].push(curr);
      }

      return acc;
    }, [] as Serie[][]);

    const mergedSeries = regroupedSeries
      .map((sGrouped) => sumAll(sGrouped))
      .map((sMerged) => {
        sMerged.globalTags.countryCode = 0;
        return sMerged;
      });

    return mergedSeries;
  };
};

/**
 * Cross country distribution of the respondents
 * A <label="Distribution of the respondents"> global tag is added to the serie
 *
 * @param population
 * @param series
 * @returns
 */
export const audienceCrossCountryResponsePercentageSeries = (series: Serie[]): (() => Serie[]) => {
  return () => {
    const weightedCountSeries = sumByCountry(() =>
      series.filter((serie) => serieGlobalTagsMatch({ isWeighted: true })(serie)),
    );

    return weightedCountSeries()
      .map((serie: Serie) => {
        const distributionBaseComparaison = serie.globalTags.distributionBaseComparaison as number;
        return serieReduce(serie, (n: number) => {
          return Number(((n / distributionBaseComparaison) * 100).toFixed(1));
        });
      })
      .map((serie) => ({ ...serie, globalTags: { ...serie.globalTags, label: 'Distribution of the respondents' } }));
  };
};

/**
 * Cross country Index serie
 * A <label="Index"> global tag is added to the serie
 *
 * @param population
 * @param series
 * @returns
 */

export const countryIndexSeries = (series: Serie[], variableDetails: Serie[]): (() => Serie[]) => {
  return () => {
    const weightedCountSeries = sumByCountry(() =>
      series.filter((serie) => serieGlobalTagsMatch({ isWeighted: true })(serie)),
    );

    return weightedCountSeries()
      .map((serie: Serie) => {
        const vdSeries = variableDetails.filter((vds) =>
          serieGlobalTagsMatch({
            variableId: serie.globalTags.variableId,
          })(vds),
        );

        if (vdSeries.length < 1) {
          throw Error('Stale variableDetail !');
        }

        const mergedVdSerie = sumAll(vdSeries);
        const selectedResponses = serie.tags.map((tag) => tag['responseId']);
        const filteredVdSerie = serieTagFilter((tag: SerieTag) => selectedResponses.includes(tag.responseId as string))(
          mergedVdSerie,
        );

        const countryTotal = filteredVdSerie.globalTags.countryTotal as number;
        const countryPercentageSeries = serieRatio(filteredVdSerie, 100 / countryTotal);

        const audiencePercentageSeries = serieReduce(serie, (n: number) => {
          const distributionBaseComparaison = serie.globalTags.distributionBaseComparaison as number;
          return Number(((n / distributionBaseComparaison) * 100).toFixed(1));
        });

        return serieReduce(audiencePercentageSeries, (n: number, i: number) => {
          return Number(((n / countryPercentageSeries.values[i]) * 100).toFixed(0));
        });
      })
      .map((serie) => ({ ...serie, globalTags: { ...serie.globalTags, label: 'Index' } }));
  };
};
