/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SegmentWithUiMeta } from 'hawaii';

export interface ImporterSegmentSlice {
  list: SegmentWithUiMeta[];
}
const initialState: ImporterSegmentSlice = {
  list: [],
};

const importerSegmentSlice = createSlice({
  name: 'importerSegments',
  initialState: initialState,
  reducers: {
    setImporterSEgments: (draft: ImporterSegmentSlice, action: PayloadAction<SegmentWithUiMeta[]>) => {
      return { list: action.payload };
    },
  },
});

export const { setImporterSEgments } = importerSegmentSlice.actions;

export default importerSegmentSlice;
