/* eslint-disable */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { isACriteriaStack } from '../../core/segment/criteria';
import {
  addCriteria,
  findCriteriaById,
  removeCriteria,
  replaceCriteria,
  segmentGenerateId,
} from '../../core/segment/helper';
import { GlobalState } from './global';
import { saveSegmentinProfilesThunk, copySegmentProfilesThunk } from './thunk/saveSegment';
import { error, info } from '../Common/Message';
import { retrieveStatThunk } from './thunk/retrieveStats';
import {
  CountryMetadata,
  DataScope,
  PanelCriteria,
  ResponseInfos,
  VariableInfos,
  SegmentWithUiMeta,
  PanelCriteriaStack,
} from 'hawaii';
import { saveAudienceInAmdmThunk } from './thunk/amdm/saveAudience';

export interface SegmentWithUiMetaWithOldId extends SegmentWithUiMeta {
  oldSegmentId?: string;
  isSmart?: boolean | undefined;
}

const segmentSlice = createSlice({
  name: 'segment',
  initialState: [] as SegmentWithUiMetaWithOldId[],
  reducers: {
    setStats(draft: SegmentWithUiMetaWithOldId[], action: PayloadAction<{ id: string; stats: number }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      segment.uiMeta.stats = action.payload.stats;
      draft[index] = { ...segment };
    },

    setIsSmart(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.active = !segment.smartBuilder.active;
      }
      draft[index] = { ...segment };
    },

    setSmartPrompt(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string; prompt: string }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.runId = '';
        segment.smartBuilder.recomannderRunId = '';
        segment.smartBuilder.sentimentStatus = '';
        segment.smartBuilder.recommanderStatus = '';
        segment.smartBuilder.audienceBuilderStatus = '';
        segment.smartBuilder.audienceBuilderRunIds = [];
        segment.smartBuilder.selectedSubAudiencies = [];
        segment.smartBuilder.savedSubAudiencies = [];
        segment.smartBuilder.settings = {
          MAX_ALT_SEGMENT: 0,
          MAX_ALT_QUESTION: 0,
          RESULT_VALIDATION: '',
          VALIDATION_SCORE: '',
        };
        segment.smartBuilder.recommander = [
          {
            title: '',
            options: [],
          },
        ];
        segment.smartBuilder.sentiments = {
          negative: [],
          positive: [],
        };

        segment.smartBuilder.prompt = action.payload.prompt;
      }
      draft[index] = { ...segment };
    },

    setSmartRunID(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string; runId: string }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.runId = action.payload.runId;
      }
      draft[index] = { ...segment };
    },

    setSmartAudienceBuilderRunIds(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string; runIds: string[] }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.audienceBuilderRunIds = action.payload.runIds;
      }
      draft[index] = { ...segment };
    },

    setSmartRecommanderRunID(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string; runId: string }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.recomannderRunId = action.payload.runId;
      }
      draft[index] = { ...segment };
    },

    setSmartLevel(draft: SegmentWithUiMeta[], action: PayloadAction<{ id: string; level: string }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.level = action.payload.level;
      }
      draft[index] = { ...segment };
    },

    setSmartSettings(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        settings: {
          MAX_ALT_SEGMENT: number;
          MAX_ALT_QUESTION: number;
          RESULT_VALIDATION: string;
          VALIDATION_SCORE: string;
        };
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.settings = action.payload.settings;
      }
      draft[index] = { ...segment };
    },

    setSmartRecommanderStatus(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        recommanderStatus: string;
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.recommanderStatus = action.payload.recommanderStatus;
      }
      draft[index] = { ...segment };
    },

    setSmartSavedSubAudiences(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        subAudiences: { index: number; options: number[] }[];
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        const savedAud = segment.smartBuilder.savedSubAudiencies;
        const newSubAud = action.payload.subAudiences;

        newSubAud.forEach((newAudience) => {
          savedAud.some((audience) => audience.index === newAudience.index)
            ? savedAud.forEach((audience) => {
                if (audience.index === newAudience.index) {
                  audience.options = Array.from(new Set([...audience.options, ...newAudience.options]));
                }
              })
            : savedAud.push(newAudience);
        });
      }
      draft[index] = { ...segment };
    },

    setSmartRecommander(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        recommander: {
          title: string;
          options: {
            value: string;
            label: string;
          }[];
        }[];
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.recommander = action.payload.recommander;
      }
      draft[index] = { ...segment };
    },

    setSmartSelectedSubAudiencies(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        subAudiences: { index: number; option: number };
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }

      const segment = draft[index];

      if (segment.smartBuilder) {
        const selectedAudience = segment.smartBuilder.selectedSubAudiencies;
        const selectedIndex = action.payload.subAudiences.index;
        const selectedOptionIndex = action.payload.subAudiences.option;
        const idxAudience = segment.smartBuilder.selectedSubAudiencies.findIndex(
          (audience) => audience.index === selectedIndex,
        );

        if (idxAudience !== -1) {
          const currentOptions = selectedAudience[idxAudience].options;
          const optIndex = currentOptions.indexOf(selectedOptionIndex);

          if (optIndex !== -1) {
            currentOptions.splice(optIndex, 1);
            if (currentOptions.length === 0) {
              selectedAudience.splice(idxAudience, 1);
            } else {
              selectedAudience[idxAudience].options = currentOptions;
            }
          } else {
            selectedAudience[idxAudience].options.push(selectedOptionIndex);
          }
        } else {
          segment.smartBuilder.selectedSubAudiencies = [
            ...segment.smartBuilder.selectedSubAudiencies,
            { index: selectedIndex, options: [selectedOptionIndex] },
          ];
        }
      }

      draft[index] = { ...segment };
    },

    setSmartSentimentStatus(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        sentimentStatus: string;
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.sentimentStatus = action.payload.sentimentStatus;
      }
      draft[index] = { ...segment };
    },

    setSmartAudienceBuilderStatus(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        audienceBuilderStatus: string;
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.audienceBuilderStatus = action.payload.audienceBuilderStatus;
      }
      draft[index] = { ...segment };
    },

    setSmartSentiments(
      draft: SegmentWithUiMeta[],
      action: PayloadAction<{
        id: string;
        sentiments: {
          negative: string[];
          positive: string[];
        };
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'segment not found!';
      }
      const segment = draft[index];
      if (segment.smartBuilder) {
        segment.smartBuilder.sentiments = action.payload.sentiments;
      }
      draft[index] = { ...segment };
    },

    computing(draft: SegmentWithUiMetaWithOldId[], action: PayloadAction<{ id: string; computing: boolean }>) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index !== -1) {
        const segment = draft[index];
        segment.uiMeta.isComputing = action.payload.computing;
        draft[index] = { ...segment };
      }
    },

    reset() {
      return [];
    },

    insertSegment(draft: SegmentWithUiMetaWithOldId[], action: PayloadAction<{ segment: SegmentWithUiMetaWithOldId }>) {
      const index = draft.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === action.payload.segment.id);
      if (index === -1) {
        draft.push(action.payload.segment);
      } else {
        draft[index] = action.payload.segment;
      }
    },

    /**
     * Replace the segment with oldSegmentId by the given segment
     *
     * @param   {SegmentWithUiMetaWithOldId[]}                    draft         state
     * @param   {PayloadAction<oldSegmentId>}  action
     * @param   {string}                       oldSegmentId  oldSegmentId
     * @param   {SegmentWithUiMetaWithOldId}                      segment       segment
     *
     */
    replaceSegment(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ oldSegmentId: string; segment: SegmentWithUiMetaWithOldId }>,
    ) {
      const index = draft.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === action.payload.oldSegmentId);
      if (index === -1) {
        draft.push(action.payload.segment);
      } else {
        draft[index] = action.payload.segment;
      }
    },

    removeInvalidSegmentClusters(draft: SegmentWithUiMetaWithOldId[]) {
      return (draft = draft.filter(
        ({ criterias }) =>
          criterias.operator !== 'OR' &&
          criterias.criterias.every(
            (criteria) =>
              !(criteria as PanelCriteriaStack).operator || (criteria as PanelCriteriaStack).operator !== 'OR',
          ),
      ));
    },

    removeSegment(draft: SegmentWithUiMetaWithOldId[], action: PayloadAction<{ id: string }>) {
      return draft.filter((segment: SegmentWithUiMetaWithOldId) => segment.id !== action.payload.id);
    },

    propertiesUpdate(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ id: string; color: string; name: string }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index !== -1) {
        const segment = draft[index];
        segment.color = action.payload.color;
        segment.name = action.payload.name;
        segment.uiMeta.modified = true;
        draft[index] = { ...segment };
      }
    },

    segmentAddCriteria(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{
        id: string;
        criteriaId: string | null;
        selectedVariable: VariableInfos;
        selectedResponses: ResponseInfos[];
        operator: string;
      }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }
      const segment = draft[index];
      const criteria: PanelCriteria = {
        id: segmentGenerateId(),
        variable: action.payload.selectedVariable,
        responses: action.payload.selectedResponses,
        rule: 'INCLUDE',
      };

      const altered = addCriteria(segment, action.payload.criteriaId, action.payload.operator, criteria);

      const mergeDuplicateResponses = () => {
        interface Criteria {
          id: string;
          variable: {
            category_1: string;
            category_2: string;
            category_3: string;
            isparent: 0 | 1;
            variable_id: string;
            variable_name: string;
            variable_text: string;
            response_id: string;
          };
          responses: { label: string; parentResponseId: string; value: string; variableId: string }[];
          rule: 'INCLUDE' | 'EXCLUDE';
        }

        altered.criterias.criterias = altered.criterias.criterias.reduce<Criteria[]>((acc, item) => {
          const exists = acc.find(
            (crit: Criteria) =>
              crit.variable &&
              crit.variable.variable_id &&
              crit.variable.variable_id === (item as Criteria)?.variable?.variable_id,
          ) as Criteria | undefined;

          if (exists) {
            exists.responses = exists.responses.concat((item as Criteria).responses);
          } else {
            acc.push({ ...(item as Criteria) });
          }
          return acc;
        }, []);
      };

      // mergeDuplicateResponses();

      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    segmentRemoveCriteria(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ id: string; criteriaId: string }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);
      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }
      const segment = draft[index];

      const altered = removeCriteria(segment, action.payload.criteriaId);

      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    smartRemoveSelectedCriteria(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ id: string; criteriaId: string }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.id);

      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }

      let segment = JSON.parse(JSON.stringify(draft[index])) as SegmentWithUiMetaWithOldId;
      const id = action.payload.criteriaId;
      const optionValues = segment.smartBuilder?.recommander.flatMap((group) => group.options) || [];
      const existsOptValues = optionValues.find((opt) => opt.value.split(', ').some((val) => id.startsWith(val)));
      const splitOptionValues = existsOptValues?.value.split(', ') || [];
      const stillSomeOptions = splitOptionValues.some((bix) =>
        (segment.criterias.criterias as PanelCriteria[]).some((crit) => crit.variable.variable_id.startsWith(bix)),
      );

      if (stillSomeOptions || !segment.smartBuilder) {
        return;
      } else {
        const optionsIdx =
          segment.smartBuilder?.recommander
            .map((element, index) => ({
              idx: index,
              optionIdx: element.options.findIndex((opt) => opt.value.split(', ').some((val) => id.startsWith(val))),
            }))
            .filter((item) => item.optionIdx !== -1) || [];

        const removeSelectedSavedQuestions = (
          options: { index: number; options: number[] }[],
        ): { index: number; options: number[] }[] =>
          optionsIdx.reduce(
            (acc, { idx, optionIdx }) =>
              acc.flatMap((entry) =>
                entry.index === idx
                  ? entry.options.filter((optIdx) => optIdx !== optionIdx).length > 0
                    ? [{ index: entry.index, options: entry.options.filter((idxOpt) => idxOpt !== optionIdx) }]
                    : []
                  : entry,
              ),
            options,
          );

        const updateSelectedOptions = removeSelectedSavedQuestions(segment.smartBuilder?.selectedSubAudiencies || []);
        const updateSavedOptions = removeSelectedSavedQuestions(segment.smartBuilder?.savedSubAudiencies || []);
        segment.smartBuilder.selectedSubAudiencies = updateSelectedOptions;
        segment.smartBuilder.savedSubAudiencies = updateSavedOptions;
        draft[index] = segment;
      }
    },

    updateResponse(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ segmentId: string; criteriaId: string; selectedResponses: ResponseInfos[] }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.segmentId);
      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }
      if (action.payload.criteriaId === null) {
        throw 'Cannot target top level to find responses.';
      }
      const segment = draft[index];
      const criteria = findCriteriaById(segment, action.payload.criteriaId);

      if (criteria === undefined) {
        throw 'Criteria not found.';
      }
      if (isACriteriaStack(criteria)) {
        throw 'No responses in a criteria stack.';
      }

      const altered = replaceCriteria(segment, action.payload.criteriaId, {
        ...criteria,
        responses: action.payload.selectedResponses,
      });

      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    removeResponse(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ segmentId: string; criteriaId: string; responseValue: string }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.segmentId);
      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }
      if (action.payload.criteriaId === null) {
        throw 'Cannot target top level to find responses.';
      }
      const segment = draft[index];
      const criteria = findCriteriaById(segment, action.payload.criteriaId);

      if (criteria === undefined) {
        throw 'Criteria not found.';
      }
      if (isACriteriaStack(criteria)) {
        throw 'No responses in a criteria stack.';
      }

      const responses = criteria.responses.filter(
        (response: ResponseInfos) => response.value !== action.payload.responseValue,
      );

      const altered = replaceCriteria(segment, action.payload.criteriaId, {
        ...criteria,
        responses,
      });

      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    updateZIndex(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ segmentId: string; criteriaId: string; zIndex: number }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.segmentId);
      if (index === -1) {
        throw 'Unable to find segment to edit !';
      }
      const segment = draft[index];
      const criteria = findCriteriaById(segment, action.payload.criteriaId);
      if (criteria === undefined) {
        throw 'unfound criteria';
      }
      if (!isACriteriaStack(criteria)) {
        throw 'cannot change zIndex of non stacked criteria';
      }

      const altered = replaceCriteria(segment, criteria.id, { ...criteria, zIndex: action.payload.zIndex });
      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    updateRule(
      draft: SegmentWithUiMetaWithOldId[],
      action: PayloadAction<{ segmentId: string; criteriaId: string; rule: 'INCLUDE' | 'EXCLUDE' }>,
    ) {
      const index = draft.findIndex((segment) => segment.id === action.payload.segmentId);
      if (index === -1) {
        throw 'Unable to find segment to change !';
      }
      const segment = draft[index];
      const criteria = findCriteriaById(segment, action.payload.criteriaId);
      if (criteria === undefined) {
        throw 'unfound criteria';
      }
      if (isACriteriaStack(criteria)) {
        throw 'Stacked criteria has no rule implemented';
      }
      const altered = replaceCriteria(segment, criteria.id, { ...criteria, rule: action.payload.rule });
      altered.uiMeta.modified = true;
      draft[index] = altered;
    },

    setIrrelevancy(state: SegmentWithUiMetaWithOldId[], action: PayloadAction<{ segmentId: string; value: boolean }>) {
      const index = state.findIndex((segment) => segment.id === action.payload.segmentId);
      if (index === -1) {
        throw 'Unable to find segment to change !';
      }
      const segment = state[index];
      segment.uiMeta.irrelevant = action.payload.value;

      state[index] = { ...segment };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveSegmentinProfilesThunk.fulfilled, (state: SegmentWithUiMetaWithOldId[], action) => {
      const { oldSegmentId, segment } = action.payload;
      const index = state.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === oldSegmentId);
      const updatedSegment = {
        ...segment,
        oldSegmentId,
      };
      if (index === -1) {
        state.push(updatedSegment);
      } else {
        state[index] = updatedSegment;
      }
      info(`Segment "${segment.name}" successfuly saved.`);
    });

    builder.addCase(copySegmentProfilesThunk.fulfilled, (state: SegmentWithUiMetaWithOldId[], action) => {
      const { oldSegmentId, segment } = action.payload;
      const index = state.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === oldSegmentId);
      if (index === -1) {
        state.push(segment);
      } else {
        state[index] = segment;
      }
      info(`Segment "${segment.name}" successfuly copied.`);
    });

    builder.addCase(copySegmentProfilesThunk.rejected, (state: SegmentWithUiMetaWithOldId[], action) => {
      error(action.error.message!);
    });

    builder.addCase(saveSegmentinProfilesThunk.rejected, (state: SegmentWithUiMetaWithOldId[], action) => {
      error(action.error.message!);
    });

    builder.addCase(saveAudienceInAmdmThunk.rejected, (state: SegmentWithUiMetaWithOldId[], action) => {
      error(action.error.message!);
    });

    builder.addCase(retrieveStatThunk.fulfilled, (state: SegmentWithUiMetaWithOldId[], action) => {
      const stats = action.payload;
      const segment = action.meta.arg.segment;
      const index = state.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === segment.id);

      if (index !== -1) {
        const s = state[index];
        s.uiMeta.isComputing = false;
        s.uiMeta.stats = stats.count;
        s.uiMeta.totalRespondents = stats.totalRespondents;
        s.uiMeta.marketSize = stats.marketSize;
        s.uiMeta.irrelevant = stats.count < 50;
        state[index] = s;
      }
    });

    builder.addCase(retrieveStatThunk.pending, (state: SegmentWithUiMetaWithOldId[], action) => {
      const segment = action.meta.arg.segment;
      const index = state.findIndex((s: SegmentWithUiMetaWithOldId) => s.id === segment.id);
      if (index !== -1) {
        const s = state[index];
        s.uiMeta.isComputing = true;
        state[index] = s;
      }
    });

    builder.addCase(retrieveStatThunk.rejected, (state: SegmentWithUiMetaWithOldId[], action) => {
      error(action.error.message!);
    });
  },
});

export default segmentSlice;

// ****************************
//          SELECTORS
// ****************************

export const selectSegmentById = createSelector(
  [(state: GlobalState) => state.segments, (state: GlobalState, id: string) => ({ id })],
  (segments: SegmentWithUiMeta[], { id }: { id: string }) =>
    segments.filter((segment: SegmentWithUiMeta) => segment.id === id)[0],
);

export const selectSegmentByIdAndOldId = createSelector(
  [
    (state: GlobalState) => state.segments,
    (state: GlobalState, { id, oldSegmentId }: { id: string; oldSegmentId?: string }) => ({ id, oldSegmentId }),
  ],
  (segments: SegmentWithUiMetaWithOldId[], { id, oldSegmentId }: { id: string; oldSegmentId?: string }) => {
    return segments.filter((segment: SegmentWithUiMetaWithOldId) => {
      return segment.oldSegmentId ? segment.oldSegmentId === oldSegmentId : segment.id === id;
    })[0];
  },
);
/**
 * return the segments from the stack,
 * filtered by the dataScope and an array of countries (CountryDim)
 */
export const selectSegmentByScope = createSelector(
  [
    (state: GlobalState) => state.segments,
    (state: GlobalState, dataScope: DataScope, countries: CountryMetadata[]) => ({ dataScope, countries }),
  ],
  (
    segments: SegmentWithUiMetaWithOldId[],
    { dataScope, countries }: { dataScope: DataScope; countries: CountryMetadata[] },
  ) => {
    return segments
      .filter((segment: SegmentWithUiMetaWithOldId) => segment.dataScope === dataScope)
      .filter(
        (segment: SegmentWithUiMetaWithOldId) =>
          countries.length === segment.countryIsos.length &&
          segment.countryIsos.every(
            (country: string) =>
              countries.findIndex((selCountry: CountryMetadata) => selCountry.iso === country) !== -1,
          ),
      );
  },
);
