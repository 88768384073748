import './BrandBuilder.less';
import React, { FC, useEffect, useState, useMemo } from 'react';
import InputForm from './components/input-form';
import RadioGroups from './components/radio-groups';
import { Divider, Select, Spin, Modal } from 'antd';
import { useBrandData } from './hooks/useBrandsData';
import {
  ReadOutlined,
  LoadingOutlined,
  ThunderboltOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useDataBricks } from '../common/hooks/useDataBricks';

import ButtonIcon from '../../Common/ButtonIcon';
import { useNavigate, useParams } from 'react-router';
import { PageProperties } from '../../interface';
import { IBodyPropensity, IBrandsDetails, SegmentJobState } from '../common/interface/interface';
import { CountryMetadata } from 'hawaii';
import { IconRemove } from '../../Svg/HavasIcons';

interface Props {
  selectedCountry: CountryMetadata;
  selectedBrand: string;
  setSelectedBrand: React.Dispatch<React.SetStateAction<string>>;
  setOnProcessBrand: React.Dispatch<React.SetStateAction<IBrandsDetails[] | []>>;
  setModelRunned: React.Dispatch<React.SetStateAction<boolean>>;
  modelRunned: boolean;
}

const BrandBuilder: FC<Props> = ({
  selectedCountry,
  selectedBrand,
  setSelectedBrand,
  setOnProcessBrand,
  setModelRunned,
  modelRunned,
}) => {
  const {
    brandsResponseData,
    setBrandsResponseData,
    loading,
    getBrands,
    getHistoryBrands,
    saveHistoryBrands,
    getHistoryBrandsClusters,
    deleteHistoryBrandsClusters,
  } = useBrandData(selectedCountry.id);
  const [selectedCategory, setSelectedCategory] = useState<string | null>();
  const { startPropensityJobPipeline, databricksList } = useDataBricks(selectedBrand || '', 'propensity');
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [historySelected, setHistorySelected] = useState<
    | {
        name: string;
        category: string;
        id: number;
        date: string;
        country: number;
      }[]
    | []
  >([]);
  const historyBrands = getHistoryBrands();
  const { brandID } = useParams();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const navigateTo = (brandID?: string) =>
    navigate(
      brandID
        ? PageProperties['propensity-modeling'].url.replace(':country', selectedCountry.iso) + `/${brandID}`
        : PageProperties['propensity-modeling'].url.replace(':country', selectedCountry.iso),
    );

  useEffect(() => {
    if (!databricksList || Object.keys(databricksList).length === 0) return;

    const byCountry = {} as { [key: string]: SegmentJobState };

    Object.keys(databricksList).forEach((key) =>
      databricksList[key].countryCode === selectedCountry.id ? (byCountry[key] = databricksList[key]) : '',
    );

    const propensityOnRun = Object.keys(byCountry).map((key) => ({
      brand_id: key,
      brand_name: byCountry[key]?.name,
      category4: byCountry[key]?.category,
      country_id: byCountry[key]?.countryCode,
      country_name: 'country',
      results: byCountry[key]?.results || undefined,
      savedResults: byCountry[key]?.savedResults || [],
    }));

    setOnProcessBrand((prevBrand) => {
      const update = [...prevBrand];
      if (propensityOnRun.length === 0) {
        return [];
      }
      propensityOnRun.map((el) =>
        update.findIndex((brand) => brand.brand_id === el.brand_id) === -1
          ? update.unshift(el as IBrandsDetails)
          : (update[update.findIndex((brand) => brand.brand_id === el.brand_id)] = el as IBrandsDetails),
      );

      return update;
    });
  }, [databricksList, selectedCountry]);

  useEffect(() => {
    if (!brandsResponseData) return;
    setSelectedCategory(Object.keys(brandsResponseData)[0]);
  }, [brandsResponseData]);

  useEffect(() => {
    setSelectedBrand('');
    setInputValue(undefined);
    setBrandsResponseData(null);
    setSelectedCategory(null);
    setHistorySelected([]);
    setOnProcessBrand((prevBrand) => [...prevBrand].filter((el) => el.country_id === selectedCountry.id));
  }, [selectedCountry]);

  const runHistoryModel = () => {
    if (historySelected.length === 0) return;
    setSelectedBrand(historySelected[0]?.id.toString());
    const fetch = async (brand: { name: string; category: string; id: number; date: string; country: number }) => {
      await getHistoryBrandsClusters(brand, () => handleRunPropensity(true));
      setModelRunned(false);
    };
    historySelected.map((item) => void fetch(item));
  };

  const handleBrandSearch = async (value: string | undefined) => {
    if (!value) return;
    await getBrands(value);
  };

  const handleRunPropensity = async (force?: boolean) => {
    setModelRunned(true);
    if (historySelected.length !== 0 && !force) {
      runHistoryModel();
      setHistorySelected([]);
    } else {
      if (!selectedBrand || !brandsResponseData || !selectedCategory) return;

      const findBrand = brandsResponseData[selectedCategory].find(
        (brand) => brand.brand_id === selectedBrand.toString(),
      );

      if (!findBrand) return;

      const body: IBodyPropensity = {
        job_id: null,
        brand_ID: Number(selectedBrand),
        country_ID: selectedCountry.id,
      };

      const brandName = findBrand?.brand_name || '';
      const brandCategory = findBrand?.category4 || '';

      await startPropensityJobPipeline(body, selectedBrand, brandName, brandCategory);
      await saveHistoryBrands({
        name: brandName,
        category: brandCategory,
        id: Number(selectedBrand),
        date: getLastThursday(),
        country: selectedCountry.id,
      });

      navigateTo(selectedBrand);
      setModelRunned(false);
    }
  };

  const handleSelectedBrand = (value: string) => {
    setSelectedBrand(value);
    setHistorySelected([]);
    if (brandID) {
      navigateTo();
    }
  };

  const handleBrandHistoryClick = (brand: {
    name: string;
    category: string;
    id: number;
    date: string;
    country: number;
  }) => {
    if (databricksList && Object.keys(databricksList).includes(brand.id.toString())) {
      navigateTo(brand.id.toString());
      setSelectedBrand('');
      return;
    } else if (databricksList && Object.keys(databricksList).includes('History-' + brand.id.toString() + brand.date)) {
      navigateTo('History-' + brand.id.toString() + brand.date);
      setSelectedBrand('');
      return;
    }
    setHistorySelected((prev) =>
      prev.find((searchElement) => searchElement.id === brand.id && searchElement.date === brand.date)
        ? prev.filter((i) => i.id !== brand.id || (i.id === brand.id && i.date !== brand.date))
        : [...prev, brand],
    );

    setSelectedBrand(brand.id.toString());
    if (brandID) {
      navigateTo();
    }
  };
  const showConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <>Do you wanna remove all Previous Analysis ? </>,
      onOk: async () => {
        await deleteHistoryBrandsClusters();
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <div className="brand-builder">
      <h3>Type the brand name and select the category.</h3>
      <div className="content">
        <InputForm
          // icon={<ReadOutlined />}
          icon={null}
          title="Find your brand"
          handleBrandSearch={handleBrandSearch}
          placeholder={'Brand name'}
          setInputValue={setInputValue}
          inputValue={inputValue}
        />
        <Divider />
        <span style={{ marginBottom: '10px', display: 'block' }}>
          Select the category
          {brandsResponseData && <span className="c-comment">({Object.keys(brandsResponseData).length})</span>}
          <Select
            showSearch
            style={{ width: '100%', marginTop: '4px' }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={selectedCategory}
            onChange={(v) => setSelectedCategory(v)}
            options={
              brandsResponseData
                ? Object.keys(brandsResponseData).map((key) => ({
                    value: key,
                    label: key,
                  }))
                : []
            }
          />
        </span>
        {loading ? (
          <div className="loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        ) : (
          <>
            {brandsResponseData && selectedCategory && (
              <div className="radio-cards tiny-scroll">
                <RadioGroups
                  data={brandsResponseData[selectedCategory]}
                  handleSelectedBrand={handleSelectedBrand}
                  value={Number(selectedBrand)}
                  selectedBrandsList={(databricksList && Object.keys(databricksList)) || undefined}
                />
              </div>
            )}
          </>
        )}
        <Divider />
        <div className="label" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ display: 'block' }}>Previous Analysis</span>
          <span
            style={{
              fontSize: '12px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => showConfirm()}
          >
            <IconRemove />
          </span>
        </div>

        <div className="history-container">
          <div className="history-table">
            <div className="history-header">
              <div className="history-item history-item__name">Name</div>
              <div className="history-item history-item__category">Category</div>
              <div className="history-item">Date update</div>
            </div>
            <div className="history-body">
              {historyBrands ? (
                historyBrands
                  .sort((a, b) => {
                    const dateA = new Date(
                      Number(a.date.slice(0, 4)),
                      Number(a.date.slice(4, 6)) - 1,
                      Number(a.date.slice(6)),
                    );
                    const dateB = new Date(
                      Number(b.date.slice(0, 4)),
                      Number(b.date.slice(4, 6)) - 1,
                      Number(b.date.slice(6)),
                    );
                    if (dateA < dateB) {
                      return 1;
                    } else if (dateA > dateB) {
                      return -1;
                    } else {
                      return 0;
                    }
                  })
                  .map((el, index) => (
                    <div
                      key={index}
                      className="row"
                      onClick={() => handleBrandHistoryClick(el)}
                      style={{
                        backgroundColor: historySelected.find((item) => item.id === el.id && item.date === el.date)
                          ? '#25cacf'
                          : 'white',
                      }}
                    >
                      <div className="history-item history-item__name">{el.name}</div>
                      <div className="history-item history-item__category">
                        {el.category.length > 10 ? el.category.substring(0, 10) + '...' : el.category}
                      </div>
                      <div className="history-item">{formatDate(el.date)}</div>
                    </div>
                  ))
              ) : (
                <div className="loading">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => handleRunPropensity()} className="add-audience-btn add-audience-btn--fixed">
        <ButtonIcon
          className="btn--md"
          label="Run model"
          style={
            !selectedBrand || brandID || modelRunned
              ? { pointerEvents: 'none', opacity: '0.5' }
              : { pointerEvents: 'all', opacity: '1' }
          }
        />
      </div>
    </div>
  );
};

export default BrandBuilder;

function getLastThursday() {
  const now = new Date();
  const afterThursday = (-7 + 4 - now.getDay()) % 7;
  const currentMs = now.getTime();
  const lastThursday = new Date(currentMs + afterThursday * 24 * 60 * 60 * 1000);
  const year = lastThursday.getFullYear().toString();
  const month = (lastThursday.getMonth() + 1).toString().padStart(2, '0');
  const day = lastThursday.getDate().toString().padStart(2, '0');
  return year + month + day;
}

function formatDate(date: string) {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);

  return `${day}/${month}/${year}`;
}
