import { FC, useEffect, useMemo, useState } from 'react';
import ApplicationLayout from '../../ApplicationLayout';
import DropdownCheckbox from '../../Common/DropDownCheckbox';
import VerticalTabs from '../../Common/VerticalTabs';
import { getAvailableCountryName, getCountryDimFromIso, getCountryDimFromName } from '../../../core/helpers/country';
import { useDispatch, useSelector } from 'react-redux';
import DisplaySelector from '../../Common/DisplaySelector';
import { exportTableAsXlsx } from '../../../core/helpers/ExportTableAsXlsx';
import { Serie } from '../../../core/series/interface';
import SegmentsWrapper from '../../MetaComponents/Segments/SegmentsWrapper';
import { GlobalState } from '../../store/global';
import segmentSlice, { selectSegmentByScope } from '../../store/segmentSlice';
import {
  crossResultsAddResponses,
  crossResultsRemoveVariable,
  crossResultsToogleResponse,
} from '../../store/crossResultsSlice';
import { initialSegmentProperties } from '../../../core/segment/helper';
import { crossResulsFilterByCountry, crossResultsFilterBySelectedResponse } from '../../../core/crossResults';
import { forExcel } from '../../../core/helpers/countSeries';
import ResultsTable from './ResultTable';
import { useNavigate, useParams } from 'react-router';
import { PageProperties } from '../../interface';
import seriesSlice, { selectSegmentsSeries } from '../../store/seriesSlice';
import { CountryMetadata, ResponseInfos, SegmentOrigin, VariableInfos, countryMetadatas } from 'hawaii';
import VariableSelector from '../../MetaComponents/VariablesDrawer/VariableSelector';
import Factory from '../../../core/factory';
import { FirstLevelCategory } from '../../Common/VariableSelector/CategoriesSelector';
import { VariablesSelectionServiceInterface } from '../../../core/variablesSelection';
import { addVariableDetailsThunk } from '../../store/thunk/addVariableDetails';
import { setSelectedCountry } from '../../store/secmdmSlice';

const TOTAL_COUNTRIES_COPY = 'Total countries';
const CrossCountry: FC = () => {
  const searchCallback = async (regex: string): Promise<VariableInfos[]> => {
    return await variableService.getVariablesFromInput(regex, selectedCountriesIso, selectedLanguage, 'Harmonized');
  };

  const handleCountriesSelection = (countryNames: string[]) => {
    const countries = countryNames.map(getCountryDimFromName);
    navigate(PageProperties['cross-country'].url.replace(':countries', countries.map((c) => c.iso).join(',')));
    setFocusedCountry(countries[0]?.name || TOTAL_COUNTRIES_COPY);
  };

  const handleTabChange = (countryName: string) => {
    if (countryName !== TOTAL_COUNTRIES_COPY) {
      const country = getCountryDimFromName(countryName);
      dispatch(setSelectedCountry(country));
    }
    setFocusedCountry(countryName);
  };

  const handleAddSegment = () => {
    const countryIsos = selectedCountries.map((c) => c.iso);
    const { payload } = dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          countryIsos,
          dataScope: 'Harmonized',
          origin: SegmentOrigin.CROSS_COUNTRY,
        }),
      }),
    );
    return payload.segment;
  };

  const handleToogleResponsesChange = (variableId: string, responseId: string) => {
    dispatch(
      crossResultsToogleResponse({
        countryIsos: selectedCountries.map((c: CountryMetadata) => c.iso),
        variableId,
        responseId,
      }),
    );
  };

  const handleDeleteVariable = (variableId: string) => {
    dispatch(
      seriesSlice.actions.deleteVariableDetails({
        variableId,
        countryIsos: selectedCountries.map((c) => c.iso),
      }),
    );
    dispatch(crossResultsRemoveVariable({ variableId, countryIsos: selectedCountries.map((c) => c.iso) }));
  };

  const handleClearVariableSelection = (variableIds: string[]) => {
    variableIds.forEach((variableId) => {
      dispatch(
        seriesSlice.actions.deleteVariableDetails({
          variableId,
          countryIsos: selectedCountries.map((c) => c.iso),
        }),
      );
      dispatch(crossResultsRemoveVariable({ countryIsos: selectedCountries.map((c) => c.iso), variableId }));
    });
  };

  const xlsExport = () => {
    const exportedData = selectedCountries.map((countryDim) => {
      return {
        countryIso: countryDim.iso,
        data: forExcel(
          () =>
            crossResultsFilterBySelectedResponse(selectedResponses, crossResulsFilterByCountry(countryDim.id, series)),
          () => variableDetails,
          [countryDim],
        )(),
      };
    });

    if (selectedCountries.length > 1) {
      exportedData.push({
        countryIso: 'tt',
        data: forExcel(
          () => crossResultsFilterBySelectedResponse(selectedResponses, series),
          () => variableDetails,
          selectedCountries,
        )(),
      });
    }

    const regex = /([0-9]+)-([0-9]+)-([0-9]+)/g;
    exportTableAsXlsx(
      segments,
      exportedData,
      'Excel',
      selectedCountries,
      hawaiiState.surveyRefreshDate.replace(regex, '$3/$2/$1'),
    );
  };

  const categoryTreeProvider = (path: string[]): Promise<FirstLevelCategory[]> => {
    return categoryService.get(
      path,
      selectedCountries.map((c: CountryMetadata) => c.iso),
      selectedLanguage,
      'Harmonized',
    );
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries: strCountry } = useParams();
  const categoryService = Factory().getCategoryService();
  const variableService = Factory().getVariableSelectionService();

  let selectedCountries: CountryMetadata[] = [];
  try {
    selectedCountries = strCountry === undefined ? [] : strCountry.split(',').map(getCountryDimFromIso);
  } catch (e) {
    console.error(e);
    console.log('Set countries list to an empty array');
  }

  const variableDetails = useSelector((state: GlobalState) => state.variablesDetails);
  const crossResults = useSelector((state: GlobalState) => state.crossResults);
  const [focusedCountry, setFocusedCountry] = useState<string | null>(TOTAL_COUNTRIES_COPY);
  const selectedCountriesIso = selectedCountries.map((c: CountryMetadata) => c.iso);
  const countriesStr = selectedCountriesIso.sort().join('');

  useEffect(() => {
    if (selectedCountriesIso.length === 1) {
      const country = getCountryDimFromIso(selectedCountriesIso[0]);
      dispatch(setSelectedCountry(country));
    } else if (!selectedCountriesIso.length) {
      dispatch(setSelectedCountry(null));
    }
  }, [selectedCountriesIso]);

  const segments = useSelector((state: GlobalState) => selectSegmentByScope(state, 'Harmonized', selectedCountries));
  const selectedResponses = crossResults.selectedResponses[countriesStr] || [];
  const series = useSelector((state: GlobalState) => selectSegmentsSeries(state, segments));
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);

  const [comparaisonVariableSelectorOpened, setComparaisonVariableSelectorOpened] = useState<boolean>(false);

  const tabs =
    selectedCountries.length > 1
      ? [...selectedCountries.map((c) => c.name), TOTAL_COUNTRIES_COPY]
      : selectedCountries.map((c) => c.name);

  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const validMetadataCountries = useMemo(
    () => countryMetadatas.filter(({ iso }) => countries.find((country) => country.iso === iso)),
    [countries],
  );

  const leftSide = (
    <>
      <DropdownCheckbox
        title="Country Selection"
        options={getAvailableCountryName(
          validMetadataCountries.filter(({ iso }) => (['br', 'sg', 'za', 'ar', 'co'].includes(iso) ? false : true)),
        )}
        values={selectedCountries.map((c) => c.name)}
        selectionAction={handleCountriesSelection}
      />
      {countriesStr === '' || (
        <SegmentsWrapper
          segments={segments}
          countryIsos={selectedCountriesIso}
          newSegmentAction={handleAddSegment}
          dataScope="Harmonized"
          link="segment-editor"
          origin={SegmentOrigin.CROSS_COUNTRY}
        />
      )}
    </>
  );

  const handleAddVariable = () => {
    setComparaisonVariableSelectorOpened(true);
  };

  const handleComparaisonVariableValidation = (
    selectedVariable: VariableInfos,
    selectedResponses: ResponseInfos[],
    responsePanel: ResponseInfos[],
  ) => {
    dispatch(
      crossResultsAddResponses({
        countryIsos: selectedCountriesIso,
        variableId: selectedResponses[0]?.variableId,
        responseIds: selectedResponses.map((infos: ResponseInfos) => infos.value),
      }),
    );
    selectedCountries.map((country) => {
      dispatch(
        addVariableDetailsThunk({
          countryId: country.id,
          selectedVariable,
          responsePanel,
          dataScope: 'Harmonized',
          selectedResponses,
        }),
      );
    });

    setComparaisonVariableSelectorOpened(false);
  };

  const variableIds = Object.keys(crossResults.selectedResponses[countriesStr] || {});
  // to remove duplicated :
  const variables = {} as Record<string, Serie>;
  variableDetails
    .filter((variable) => variableIds.indexOf(variable.globalTags.variableId as string) !== -1)
    .map((vDetails) => {
      return (variables[vDetails.globalTags.variableId as string] = vDetails);
    });

  const rightSide = (
    <>
      <VerticalTabs tabs={tabs} value={focusedCountry} selectAction={handleTabChange}>
        <div className="right-tab-content">
          <DisplaySelector
            xlsExportAction={xlsExport}
            selectedResponses={selectedResponses}
            onToogleSelectedResponses={handleToogleResponsesChange}
            variables={Object.values(variables)}
            deleteVariableAction={handleDeleteVariable}
            clearSelection={handleClearVariableSelection}
          />

          <div className="pit-charts">
            <ResultsTable
              series={series}
              selectedResponses={selectedResponses}
              selectedCountries={selectedCountries}
              focusedCountry={focusedCountry}
            />
            {(!selectedCountries.length || !variableIds.length) && (
              <div className="notify_content">
                <p>
                  {!selectedCountries.length
                    ? 'Select countries that you want to analyze'
                    : !variableIds.length &&
                      segments.length &&
                      segments.every(({ amdmInfos }) => amdmInfos !== null && amdmInfos !== undefined)
                    ? 'Select variables to analyze your Audience, or create other Audiences before analyzing them against attributes'
                    : ''}
                </p>
              </div>
            )}
          </div>
          <div className="pit-add-button">
            {selectedCountries.length > 0 ? (
              <input type="button" className="btn--md" value="Select a variable" onClick={handleAddVariable} />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </VerticalTabs>
      {comparaisonVariableSelectorOpened && (
        <VariableSelector
          color="#25cacf"
          categoryTreeProvider={categoryTreeProvider}
          title="Compare your audience"
          subtitle="With a selection of variables and responses"
          validationCaption="Compare"
          visible={true}
          variableService={variableService}
          countries={selectedCountriesIso}
          languageCode={selectedLanguage}
          dataScope="Harmonized"
          onClose={(variableService: VariablesSelectionServiceInterface) => {
            variableService.abort();
            setComparaisonVariableSelectorOpened(false);
          }}
          searchCallback={searchCallback}
          validateVariableChoice={handleComparaisonVariableValidation}
        />
      )}
    </>
  );

  return <ApplicationLayout pageName="cross-country" leftSide={leftSide} rightSide={rightSide} />;
};

export default CrossCountry;
