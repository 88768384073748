import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ApplicationLayout.less';
import HeaderWrapper from './Common/Header/HeaderWrapper';
import { PageProperties } from './interface';
import { GlobalState } from './store/global';
import { refreshHawaiiStateThunk } from './store/thunk/refreshHawaiiState';
import { Spin } from 'antd';
import Footer from './Common/Footer/Footer';

interface Props {
  pageName: string;
  leftSide: ReactNode;
  rightSide: ReactNode;
  pageSubtitle?: ReactNode;
}

const ApplicationLayout: FC<Props> = ({ pageName, pageSubtitle, leftSide, rightSide }) => {
  const dispatch = useDispatch();
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const appIsLoading = useSelector((state: GlobalState) => state.appIsLoadingState);

  useEffect(() => {
    dispatch(refreshHawaiiStateThunk());
  }, []);

  const Loading = useCallback(() => {
    if (appIsLoading) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            background: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)',
          }}
        >
          <Spin />
        </div>
      );
    }

    return;
  }, [appIsLoading]);

  return (
    <>
      <div className="main">
        <div className="header">
          <HeaderWrapper lastUpdate={hawaiiState.surveyRefreshDate} />
        </div>
        <div className="l-wrapper">
          <div className="l-wrapper__left">
            <div className="l-wrapper__container">
              <h1 className="h1 hawaii-title">
                {PageProperties[pageName].title}
                {pageSubtitle}
              </h1>
              {leftSide}
            </div>
          </div>
          <div className="l-wrapper__right">{rightSide}</div>
        </div>
        <Footer />
      </div>
      {Loading()}
    </>
  );
};
export default ApplicationLayout;
