import { Modal, Typography } from 'antd';
import { FC } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const { Title, Text } = Typography;

const UKPanelistAlert: FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      title={<Title style={{ fontSize: '1.7rem', textAlign: 'center' }}>Warning: Panel Size</Title>}
      open={isOpen}
      closable={true}
      centered
      width={800}
      onCancel={() => {
        setIsOpen(false);
      }}
      mask
      footer={null}
    >
      <Text style={{ fontSize: '1.2rem' }}>
        The panel size is below 3,000 panelists. Please adjust to meet the minimum requirement.
      </Text>
    </Modal>
  );
};

export { UKPanelistAlert };
