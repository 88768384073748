import { FC, useEffect, useState } from 'react';
import './SegmentEditor.less';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getCountryDimFromIso } from '../../../core/helpers/country';
import { initialSegmentProperties } from '../../../core/segment/helper';
import ApplicationLayout from '../../ApplicationLayout';
import SegmentsWrapper from '../../MetaComponents/Segments/SegmentsWrapper';
import { GlobalState } from '../../store/global';
import { getSegmentEditorData, getSegmentEditorUrlBack } from '../../store/segmentEditionSlice';
import SegmentEditor from './SegmentEditor';
import segmentSlice, { selectSegmentById, selectSegmentByScope } from '../../store/segmentSlice';
import { retrieveSegmentFromAudienceThunk } from '../../store/thunk/retrieveAudienceSegment';
import { getActivateStudioState } from '../../store/thunk/amdm/getActivateStudioState';
import { setActivateStudioState } from '../../store/activateStudioSlice';
import { SegmentStage, SmartSegmentStage } from './UJContext';
import { CountryMetadata, SegmentOrigin } from 'hawaii';
import { setSelectedCountry } from '../../store/secmdmSlice';
import { setAudienceAMDMState } from '../../store/audienceAMDMStatusSlice';

const SegmentEditorBootstrap: FC = () => {
  const handleAddSegment = (isSmart?: boolean) => {
    const { payload } = dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          countryIsos: segment.countryIsos,
          dataScope: segment.dataScope,
          origin: SegmentOrigin.SEGMENT_BUILDER,
          isSmart,
        }),
      }),
    );

    return payload.segment;
  };

  const editorData = useSelector((state: GlobalState) => getSegmentEditorData(state));
  const urlBack = useSelector((state: GlobalState) => getSegmentEditorUrlBack(state));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activateStudioState = useSelector((state: GlobalState) => state.activateStudioState);

  const [hasRetrieveSegmentData, setHasRetrieveSegmentData] = useState<boolean>(false);
  const segment = useSelector((state: GlobalState) => selectSegmentById(state, editorData.segmentId!));
  const { audienceRecordId } = useParams();

  const handleSelectCountry = (iso: string) => {
    const meta: CountryMetadata = getCountryDimFromIso(iso);
    dispatch(setSelectedCountry(meta));
  };

  useEffect(() => {
    if (segment && segment.countryIsos.length && hasRetrieveSegmentData) {
      handleSelectCountry(segment.countryIsos[0]);
    }
  }, [hasRetrieveSegmentData, segment]);

  useEffect(() => {
    (() => {
      if (audienceRecordId && !editorData.segmentId && !segment) {
        dispatch(retrieveSegmentFromAudienceThunk({ audienceRecordId }));
        setHasRetrieveSegmentData(true);
      } else if ((!segment && !audienceRecordId) || (!segment && hasRetrieveSegmentData)) {
        return navigate(urlBack);
      }
    })();
  }, [segment, audienceRecordId]);

  useEffect(() => {
    if (
      segment &&
      segment.countryIsos.length &&
      segment.amdmInfos &&
      segment.amdmInfos?.audienceRecordId &&
      !activateStudioState.activateState
    ) {
      dispatch(
        getActivateStudioState({
          audienceRecordId: segment.amdmInfos?.audienceRecordId,
          selectedCountry: segment.countryIsos[0],
        }),
      );
    }
  }, [segment, hasRetrieveSegmentData, audienceRecordId, activateStudioState]);

  useEffect(() => {
    return () => {
      dispatch(setActivateStudioState(null));
      dispatch(setAudienceAMDMState(null));
    };
  }, []);

  const segments = useSelector((state: GlobalState) =>
    segment === undefined
      ? []
      : selectSegmentByScope(state, segment.dataScope, segment.countryIsos.map(getCountryDimFromIso)),
  );

  const [selectedStage, setSelectedStage] = useState<SegmentStage | SmartSegmentStage>(
    segment?.smartBuilder?.active ? 'Smart Builder' : 'Definition',
  );

  const leftSide =
    segment !== undefined ? (
      <SegmentsWrapper
        segments={segments}
        countryIsos={segment.countryIsos}
        newSegmentAction={handleAddSegment}
        dataScope={segment.dataScope}
        link="segment-editor"
        setSelectedStage={setSelectedStage}
        origin={SegmentOrigin.SEGMENT_BUILDER}
      />
    ) : (
      <></>
    );

  const rightSide =
    segment !== undefined ? (
      <SegmentEditor
        selectedStage={selectedStage}
        setSelectedStage={setSelectedStage}
        segment={segment}
        urlBack={urlBack}
      />
    ) : (
      <></>
    );

  return segment === undefined ? (
    <></>
  ) : (
    <ApplicationLayout pageName="segment-editor" leftSide={leftSide} rightSide={rightSide} />
  );
};

export default SegmentEditorBootstrap;
