import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import axios from 'axios';
import { config } from '../../../../core/config';
import Color from 'color';
import { random } from 'lodash';
import { addBearerTokenInHeaders } from '../../../../core/helpers/addBearTokenInHeaders';
import { calculateEstTime } from '../../../../core/helpers/calculateEstTime';
import {
  databricksJobStart,
  databricksJobResults,
  propensityBQJobResults,
  propensityDataJobResults,
  setPropensityDataResults,
} from '../../../store/modules/databricks/actions';
import { SegmentJobState, IBodyCluster, IBodyPropensity, Clusters, PropensityResults } from '../interface/interface';
import { SegmentWithUiMetaWithOldId } from '../../../store/segmentSlice';

let cursorColor = random(360);

const BASE_URL = config.api.baseUrl as string;

export const useDataBricks = (actionID: string, module: string, segments: SegmentWithUiMetaWithOldId[]) => {
  const dispatch = useDispatch();
  const languageISO = useSelector((state: GlobalState): number => state.settingsUI.language.meta.id);

  const { runID, jobStatus, results, loading } = useSelector(
    (state: GlobalState) =>
      state.databricks[module]?.[actionID] || { runID: null, jobStatus: null, results: null, loading: false },
  );
  // const databricksList = useSelector((state: GlobalState): { [key: string]: SegmentJobState } | undefined => {
  //   const dataBrick = state?.databricks?.[module];
  //   if (!dataBrick || Object.keys(state.databricks[module]).length === 0) {
  //     return undefined;
  //   }
  //   const byCountry = {} as { [key: string]: SegmentJobState };
  //   Object.keys(state.databricks[module]).forEach((key) => {
  //     const item = state.databricks[module][key];
  //     if (item.countryCode === languageISO) {
  //       byCountry[key] = item;
  //     }
  //   });
  //   return byCountry;
  // });

  const databricksList = useSelector(
    (state: GlobalState): { [key: string]: SegmentJobState } => state.databricks[module],
  );

  const startClusterAnalysisPipeline = useCallback(
    async (body: IBodyCluster, segmentID: string) => {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      body.job_id = 953486935081251;

      const runJob = await axios.post<{
        run_id: number;
        number_in_job: number;
      }>(`${BASE_URL}/v1/${module}/databrick/run-now`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });

      if (runJob && runJob.status === 200) {
        const results = {
          run_id: runJob.data.run_id.toString(),
          status: 'RUNNING',
          statusBQ: undefined,
          name: undefined,
          category: undefined,
          loading: true,
          jobTimeStart: new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
          estimatedTime: calculateEstTime(segmentID, body.num_cluster, segments),
          countryCode: languageISO,
        };

        dispatch(databricksJobStart(results, segmentID, module));
      }
    },
    [dispatch, segments],
  );

  const getResultsClusterAnalysisPipeline = useCallback(
    async (segmentID: string) => {
      if (!databricksList) return;
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const segment = segments.find((segment) => segment.publicId === segmentID);
      const clusterRunID = databricksList[segmentID].runID;
      const jobResults = await axios.post<{
        status: string;
        response: Clusters;
      }>(
        `${BASE_URL}/v1/${module}/databrick/runs-get`,
        {
          run_id: clusterRunID,
          country_code: languageISO,
          audience_ID: segment?.name,
        },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );

      if (jobResults && jobResults.status === 200 && jobResults.data.status === 'SUCCESS') {
        jobResults.data.response.colors =
          jobResults.data.response?.cluster_profile?.map((_) => Color.hsl((cursorColor += 100), 70, 50).hex()) || [];
        const results = {
          status: jobResults.data.status,
          response: jobResults.data.response,
          loading: false,
        };

        dispatch(databricksJobResults(results, segmentID, module));
      } else if (
        jobResults &&
        jobResults.status === 200 &&
        jobResults.data.status !== 'SUCCESS' &&
        jobResults.data.status !== 'RUNNING'
      ) {
        const results = {
          status: jobResults.data.status,
          response: jobResults.data.response,
          loading: false,
        };

        dispatch(databricksJobResults(results, segmentID, module));
      }
    },
    [dispatch, segments],
  );

  const getPrevClusterAnalysis = useCallback(
    async (segmentID: string, date: string) => {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const segment = segments.find((segment) => segment.publicId === segmentID);
      const splitDate = date.split('/');
      const formatDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;

      const results = {
        run_id: 'prevAnalysis',
        status: 'RUNNING',
        statusBQ: undefined,
        name: undefined,
        category: undefined,
        loading: true,
        jobTimeStart: new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
        estimatedTime: {
          lower_bound: '00:01:00',
          upper_bound: '00:01:00',
        },
        countryCode: languageISO,
      };

      dispatch(databricksJobStart(results, segmentID, module));

      const jobResults = await axios.post<{
        status: string;
        response: Clusters;
      }>(
        `${BASE_URL}/v1/${module}/databrick/get-previous-cluster`,
        {
          country_code: languageISO,
          audience_ID: segment?.name,
          audience_date: formatDate,
        },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );

      if (jobResults && jobResults.status === 200 && jobResults.data.status === 'SUCCESS') {
        jobResults.data.response.colors =
          jobResults.data.response?.cluster_profile?.map((_) => Color.hsl((cursorColor += 100), 70, 50).hex()) || [];

        const results = {
          status: jobResults.data.status,
          response: jobResults.data.response,
          loading: false,
        };

        dispatch(databricksJobResults(results, segmentID, module));
      }
    },
    [dispatch, segments],
  );

  const startPropensityJobPipeline = useCallback(
    async (body: IBodyPropensity, brandID: string, name: string | undefined, category: string | undefined) => {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      body.job_id = 926380007164677;

      const runJob = await axios.post<{
        run_id: number;
        number_in_job: number;
      }>(`${BASE_URL}/v1/${module}/databrick/run-now`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });

      if (runJob && runJob.status === 200) {
        const results = {
          run_id: runJob.data.run_id.toString(),
          status: 'RUNNING',
          statusBQ: 'QUEUED',
          name: name,
          category: category,
          loading: true,
          jobTimeStart: new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
          estimatedTime: '00:00:00',
          countryCode: body.country_ID,
        };

        dispatch(databricksJobStart(results, brandID, module));
      }
    },
    [dispatch],
  );

  const getResutlsPropensityJobPipeline = useCallback(
    async (brandID: string) => {
      if (!databricksList) return;
      const propensity = databricksList[brandID];
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const jobResults = await axios.post<{
        status: string;
      }>(
        `${BASE_URL}/v1/${module}/databrick/runs-get`,
        {
          run_id: propensity.runID,
          country_code: languageISO,
          audience_ID: brandID,
        },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );

      if (jobResults.data.status === 'SUCCESS') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(propensityDataJobResults(jobResults.data.status, brandID, module));
      }
    },
    [dispatch, databricksList],
  );

  const startPropensityBQJobPipeline = useCallback(
    async (brandID: string, selectedCountry: number) => {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const body = {
        job_id: 152363139614845,
        brand_ID: brandID,
        country_ID: selectedCountry,
      };

      interface RunJob {
        run_id: number;
        number_in_job: number;
      }

      const runJob = await axios.post<RunJob>(`${BASE_URL}/v1/${module}/databrick/run-now`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });

      if (runJob && runJob.status === 200) {
        const results = {
          run_ID: runJob.data.run_id.toString(),
          status: 'RUNNING',
        };

        dispatch(propensityBQJobResults(results, brandID, module));
      }
    },
    [dispatch],
  );

  const getResultsPropensityBQpipeline = useCallback(
    async (brandID: string) => {
      if (!databricksList) return;
      const propensity = databricksList[brandID];
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const jobResults = await axios.post<{
        status: string;
      }>(
        `${BASE_URL}/v1/${module}/databrick/runs-get`,
        {
          run_id: propensity.runIDBQ,
          country_code: languageISO,
          audience_ID: brandID,
        },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );
      if (jobResults.data.status === 'SUCCESS') {
        await pipelinePropensityBQResults(brandID);
      }
    },
    [dispatch, databricksList],
  );

  const pipelinePropensityBQResults = useCallback(
    async (brandID: string) => {
      if (!databricksList) return;
      const propensity = databricksList[brandID];
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const jobResults = await axios.post<PropensityResults>(
        `${BASE_URL}/v1/${module}/databrick/runs-get/data`,
        {
          country_code: languageISO,
          brand_ID: brandID,
        },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );

      if (jobResults.status === 200) {
        // const grupedData = jobResults.data.propensity.reduce(
        //   (list, item) => {
        //     const key = parseFloat(item.probability).toFixed(2);
        //     if (!list.probabilities[key]) {
        //       list.probabilities[key] = [];
        //     }

        //     if (!list.yougovIDs[key]) {
        //       list.yougovIDs[key] = [];
        //     }

        //     list.yougovIDs[key].push(item.yougov_id);
        //     list.probabilities[key].push(Number(item.probability));
        //     return list;
        //   },
        //   { probabilities: {} as { [key: string]: number[] }, yougovIDs: {} as { [key: string]: string[] } },
        // );

        // const generateScale = () => {
        //   const numbers = [];
        //   for (let i = 0; i <= 100; i++) {
        //     const num = (i / 100).toFixed(2).toString();
        //     numbers.push(num);
        //   }
        //   return numbers;
        // };
        // const toChartData: [string, number][] = generateScale().redu((key) => {
        //   return [key, grupedData?.probabilities[key]?.length || 0];
        // });

        // const results = {
        //   toChartData: toChartData,
        //   yougovIDs: grupedData.yougovIDs,
        //   distinct: jobResults.data.distinct,
        //   status: 'SUCCESS',
        // };
        const results = {
          toChartData: jobResults.data.toChartData,
          yougovIDs: jobResults.data.yougovIDs,
          distinct: jobResults.data.distinct,
          topVariables: jobResults.data.topVariables,
          status: 'SUCCESS',
        };
        dispatch(setPropensityDataResults(results, brandID, module));
      }
    },
    [dispatch, databricksList],
  );

  return {
    startClusterAnalysisPipeline,
    getResultsClusterAnalysisPipeline,
    getPrevClusterAnalysis,
    startPropensityJobPipeline,
    getResutlsPropensityJobPipeline,
    startPropensityBQJobPipeline,
    getResultsPropensityBQpipeline,
    databricksList,
    jobStatus,
    results,
    loading,
  };
};
