import './MatchingStage.less';
import { FC, useContext, useEffect } from 'react';
import { MdOutlineSettings } from 'react-icons/md';
import UJContext from './UJContext';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import { IconMatch } from '../../Svg/HavasIcons';

const MatchingStage: FC = () => {
  const { segment, handleActivateStudioDataBricksMatch, selectedStage, handleBreadCrumbSelection } =
    useContext(UJContext);
  const activateStudioState = useSelector((state: GlobalState) => state.activateStudioState);
  const audienceAMDMStatus = useSelector((state: GlobalState) => state.audienceAMDMState);
  const disableButton = !audienceAMDMStatus.state || audienceAMDMStatus.state.dataStatus === 'PENDING' ? true : false;
  if (activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHING'))
    return (
      <div className="matching-progress">
        <MdOutlineSettings className="hawaii-rotating" style={{ fontSize: '4em', color: segment.color }} />
        <span>Matching in process, please wait...</span>
      </div>
    );
  else if (
    activateStudioState.activateState?.data.find(({ statusActivateStudio }) => statusActivateStudio === 'MATCHED')
  )
    return (
      <div className="matching-stage">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '4rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <p style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>Matching process completed successfully!</p>
          <p style={{ fontSize: '1.3rem' }}>
            Click here to go to 'Activate Studio' and continue with the activation process of your audience in the
            different 'Panels to activation' available in Converged.
          </p>
          <button onClick={handleActivateStudioDataBricksMatch} className="btn btn-success btn--sm">
            <span>Activate Studio</span>
          </button>
        </div>
      </div>
    );

  return (
    <div className="matching-stage">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '4rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        <p style={{ fontSize: '1.3rem' }}>
          Please, click on the 'Match' button to send this audience for activation in the different 'Panels to
          activation' in Converged - Activate Studio.
        </p>
        {disableButton ? (
          <p style={{ marginTop: '1rem' }}>
            The 'Match' button will be enabled once the audience is materialized. This process may take 15-30 minutes.
            Please wait.
          </p>
        ) : null}
        <button
          disabled={disableButton}
          onClick={handleActivateStudioDataBricksMatch}
          className="btn btn-success btn--sm"
        >
          <span>Match</span>
          <IconMatch className="c-icon-fill c-icon--20" />
        </button>
      </div>
    </div>
  );
};

export default MatchingStage;
