import './index.less';
import { FC, useState, useEffect, useMemo } from 'react';
import HeaderWrapper from '../../Common/Header/HeaderWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import { PageProperties } from '../../interface';
import BrandBuilder from '../../modules/propensity/BrandBuilder';
import Infos from '../SegmentBuilder/Infos/Infos';
import { CountryMetadata, countryMetadatas } from 'hawaii';
import { getCountryDimFromIso } from '../../../core/helpers/country';
import { useNavigate, useParams } from 'react-router';
import { useSetLanguage } from '../../Common/hooks/useSettingsUI';
import BrandResults from '../../modules/propensity/BrandResults';
import { useDataBricks } from '../../modules/common/hooks/useDataBricks';
import { refreshHawaiiStateThunk } from '../../store/thunk/refreshHawaiiState';
import { IBrandsDetails, SegmentJobState } from '../../modules/common/interface/interface';
import { setSelectedCountry } from '../../store/secmdmSlice';
import _ from 'lodash';
import Footer from '../../Common/Footer/Footer';

const Index: FC = () => {
  const { country: strCountry, brandID } = useParams();
  const dispatch = useDispatch();
  const setLanguage = useSetLanguage();
  const navigate = useNavigate();
  const [totalRespondent, setTotalRespondent] = useState<number | undefined>(undefined);
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [onProcessBrand, setOnProcessBrand] = useState<IBrandsDetails[] | []>([]);
  const [modelRunned, setModelRunned] = useState<boolean>(false);

  const handleSelectCountry = (iso: string) => {
    const meta: CountryMetadata = getCountryDimFromIso(iso);
    dispatch(setSelectedCountry(meta));
    setLanguage(iso, meta);
    setTotalRespondent(undefined);
    navigate(PageProperties['propensity-modeling'].url.replace(':country', iso));
  };

  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const user = useSelector((state: GlobalState) => state.secmdmState).user;
  const validCountries = useMemo(
    () =>
      countries
        .filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso))
        .sort((a, b) => (a.iso < b.iso ? -1 : 1)),
    [countries],
  );

  const isoAlpha2 = validCountries.find((country) => country.iso.toLowerCase() === user.isoAlpha2?.toLowerCase())
    ? user.isoAlpha2
    : validCountries[0].iso;

  const selectedCountry = useMemo(
    () => getCountryDimFromIso(!strCountry || strCountry === ':country' ? (isoAlpha2 as string) : strCountry),
    [strCountry, user],
  );

  useEffect(() => {
    handleSelectCountry(selectedCountry.iso);
  }, [selectedCountry]);

  useEffect(() => {
    dispatch(refreshHawaiiStateThunk());
  }, [!hawaiiState.surveyRefreshDate]);

  useEffect(() => {
    if (onProcessBrand.length === 0) return;
    const url =
      PageProperties['propensity-modeling'].url.replace(':country', selectedCountry.iso) +
      `/${onProcessBrand[0].brand_id}`;
    if (!brandID) {
      navigate(url);
    }
  }, [onProcessBrand]);

  const {
    getResutlsPropensityJobPipeline,
    startPropensityBQJobPipeline,
    getResultsPropensityBQpipeline,
    databricksList,
  } = useDataBricks(selectedBrand, 'propensity');

  useEffect(() => {
    if (!databricksList) return;
    const byCountry = {} as { [key: string]: SegmentJobState };
    Object.keys(databricksList).forEach((key) => {
      const item = databricksList[key];
      if (item.countryCode === selectedCountry.id) {
        byCountry[key] = item;
      }
    });

    let successNum = 0;
    const step1 = async (id: string) => {
      try {
        await getResutlsPropensityJobPipeline(id);
      } catch (error) {
        console.error('Error of running propensity first job.', error);
      }
    };
    const step2 = async (id: string) => {
      try {
        await startPropensityBQJobPipeline(id, selectedCountry.id);
      } catch (error) {
        console.error('Error of running propensity second job.', error);
      }
    };
    const step3 = async (id: string) => {
      try {
        await getResultsPropensityBQpipeline(id);
      } catch (error) {
        console.error('Error of running propensity third job.', error);
      }
    };
    if (successNum !== Object.keys(byCountry).length) {
      const interval = setInterval(() => {
        const keys = Object.keys(byCountry).filter((key) => !key.includes('History-'));
        if (keys.length === 0) {
          clearInterval(interval);
          return;
        }
        keys.forEach((key) => {
          const runIDBQStatus = byCountry[key].runIDBQStatus;
          const jobStatus = byCountry[key].jobStatus;
          if (jobStatus === 'RUNNING') {
            void step1(key);
          }

          if (jobStatus === 'SUCCESS' && runIDBQStatus === 'QUEUED') {
            void step2(key);
          }

          if (byCountry[key].jobStatus === 'SUCCESS' && byCountry[key].runIDBQStatus === 'RUNNING') {
            successNum++;
            void step3(key);
          }
        });
      }, 10000);

      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [
    getResutlsPropensityJobPipeline,
    startPropensityBQJobPipeline,
    getResultsPropensityBQpipeline,
    selectedBrand,
    databricksList,
    selectedCountry,
  ]);
  return (
    <>
      {hawaiiState && (
        <div className="general-application">
          <div className="header">
            <HeaderWrapper lastUpdate={hawaiiState.surveyRefreshDate} />
          </div>
          <div className="l-wrapper">
            <div className="l-wrapper__left thin-scrollbar">
              <div className="l-wrapper__container">
                <h1 className="hawaii-title">{PageProperties['propensity-modeling'].title}</h1>
                <Infos
                  country={selectedCountry}
                  selectCountryAction={handleSelectCountry}
                  totalRespondent={totalRespondent}
                  totalPopulationInvisible={true}
                  languageSelectorInvisible={true}
                />
                <BrandBuilder
                  selectedCountry={selectedCountry}
                  selectedBrand={selectedBrand}
                  setSelectedBrand={setSelectedBrand}
                  setOnProcessBrand={setOnProcessBrand}
                  setModelRunned={setModelRunned}
                  modelRunned={modelRunned}
                />
              </div>
            </div>
            <div className="l-wrapper__right">
              <BrandResults
                selectedCountry={selectedCountry}
                runID={selectedBrand?.toString()}
                onProcessBrand={onProcessBrand}
                setSelectedBrand={setSelectedBrand}
                jobBQStatus={brandID && databricksList ? databricksList[brandID]?.runIDBQStatus : null}
                jobStatus={brandID && databricksList ? databricksList[brandID]?.jobStatus : null}
                modelRunned={modelRunned}
              />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Index;
