import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getDataStatus } from './thunk/amdm/getDataStatus';

export type AMDMAudienceRepresentationResponse = {
  agency: string;
  agencyId: number;
  applicationId: number;
  audienceRecordId: string;
  audienceRefreshStatus: string;
  audienceStatus: string;
  dataStatus?: 'PENDING' | 'MATERIALIZED';
  audienceVersionId: string;
  client: string;
  clientId: number;
  country: string;
  countryId: number;
  creationApplicationId: number;
  creationDate: number;
  creationOwnerId: number;
  creationOwnerUpn: string;
  datasetDate: number;
  datasetEndDate: null;
  datasetId: number;
  datasetName: null;
  datasetPeriodId: number;
  datasetStartDate: null;
  endDate: null;
  expirationDate: number;
  fileId: number;
  fileName: null;
  id: string;
  label: string;
  lastModificationDate: number;
  lastVersion: boolean;
  moduleApp: string;
  name: string;
  nonPerishable: boolean;
  ownerUpn: string;
  population: number;
  queryText: null;
  sharedAudience: boolean;
  sqlText: null;
  startDate: number;
  type: string;
  versionNumber: number;
};
export type audienceAMDMStatus = {
  state: AMDMAudienceRepresentationResponse | null;
};

const initialState: audienceAMDMStatus = {
  state: null,
};

const audienceAMDMStatusSlice = createSlice({
  name: 'audienceAMDMStatus',
  initialState,
  reducers: {
    setAudienceAMDMState: (state, action: PayloadAction<AMDMAudienceRepresentationResponse | null>) => {
      state.state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataStatus.fulfilled, (state, action) => {
      state.state = action.payload;
    });
  },
});

export const { setAudienceAMDMState } = audienceAMDMStatusSlice.actions;

export default audienceAMDMStatusSlice;
